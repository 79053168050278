import { Theme, createTheme } from "@material-ui/core/styles"
import createBreakpoints, {
  Breakpoint,
  Breakpoints,
} from "@material-ui/core/styles/createBreakpoints"
import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography"
import { CSSProperties } from "react"

const spacing = 12

const breakpoints = { xs: 360, sm: 768, md: 1280, lg: 1920, xl: 2560 }
const smUp = `@media (min-width: ${breakpoints.sm}px)`
const mdUp = `@media (min-width: ${breakpoints.md}px)`

const size = {
  headerHeight: 48,
} as const

const gap = {
  containerPadding: spacing,
  containerPaddingSmUp: 26,
} as const

const colors = {
  blue: "#005AFF",
  yellowMain: "#F0BC2B",
  yellowHover: "#F2C649",
  greySuperLight: "#E8E8E8",
  greenRating: "#23A961",
  greyDark: "#767676",
  greyUltraLight: "#F5F5F5",
  greyWhite: "#FAFAFA",
  grey: "#d9d9d9",
  blackLight: "#3D3D3D",
  greyLight: "#CFCFCF",
  violet: "#B133FF",
  lightBlue: "#D4FFFC",
}

export type TThemeCs =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "body1"
  | "body2"
  | "subtitle1"
  | "subtitle2"

const h1: TypographyStyleOptions = {
  fontWeight: 700,
  fontSize: "28px",
  lineHeight: "36px",
  color: "#000",
  [smUp]: {
    fontSize: "36px",
    lineHeight: "48px",
  },
  [mdUp]: {
    fontSize: "48px",
    lineHeight: "56px",
  },
}
const h2: TypographyStyleOptions = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "34px",
  [mdUp]: {
    fontSize: "36px",
    lineHeight: "42px",
  },
}
const h3: TypographyStyleOptions = {
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "27px",
  [mdUp]: {
    fontSize: "24px",
    lineHeight: "28px",
  },
}
const h4: TypographyStyleOptions = {
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "27px",
  [mdUp]: {
    fontSize: "18px",
    lineHeight: "27px",
  },
}
const h5: TypographyStyleOptions = {
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "22px",
}
const body1: TypographyStyleOptions = {
  fontSize: "18px",
  lineHeight: "27px",
  fontWeight: 400,
}
const body2: TypographyStyleOptions = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
}
const subtitle2: TypographyStyleOptions = {
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: 400,
}
const subtitle1: TypographyStyleOptions = {
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 400,
}

const customTheme = {
  size,
  gap,
  colors,
}

const getMuiTheme = () => {
  return createTheme({
    breakpoints: {
      values: breakpoints,
    },
    spacing: spacing,
    palette: {
      primary: { main: colors.yellowMain },
      secondary: {
        light: "#3974f9",
        main: "#1D4DB9",
        dark: "#4c83f9",
        contrastText: "#FFF",
      },
      text: { primary: colors.blackLight, secondary: colors.greyDark },
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif", "Arial"].join(","),
      h1: h1, //H1
      h2: h2, //H2
      h3: h3, //H3
      h4: h4, //H4
      h5: h5, //Title
      body1: body1, //Text1
      body2: body2, //Text2
      subtitle1: subtitle1, //Caption1
      subtitle2: subtitle2, //Caption2
    },
    overrides: {
      MuiContainer: {
        root: {
          maxWidth: "1280px !important",
          paddingLeft: gap.containerPadding,
          paddingRight: gap.containerPadding,
          [smUp]: {
            paddingLeft: gap.containerPaddingSmUp,
            paddingRight: gap.containerPaddingSmUp,
          },
        },
      },
      MuiToolbar: {
        regular: {
          height: "80px",
        },
      },
      MuiButton: {
        root: {
          textTransform: "none",
          borderRadius: "4px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "fit-content",
          boxShadow: "none !important",
        },
        label: {
          position: "relative", //ripple не перекрывает label
          zIndex: 2,
        },
        containedPrimary: {
          backgroundColor: colors.yellowMain,
          color: "#000",
          "&:hover": {
            backgroundColor: colors.yellowHover,
          },
          "& .MuiTouchRipple-root": {
            color: "#d69e00",
          },
          "@media (hover: none)": {
            "&:hover": {
              backgroundColor: colors.yellowMain + " !important",
            },
          },
        },
        outlinedPrimary: {
          color: "#000",
          backgroundColor: "transparent",
          border: `2px solid ${colors.yellowMain}`,
          "&:hover": {
            backgroundColor: "#FDF7E5",
            border: `2px solid ${colors.yellowMain}`,
          },
          "& .MuiTouchRipple-root": {
            color: "transparent",
          },
        },
        containedSecondary: {
          backgroundColor: colors.blackLight,
          color: "#fff",
          "&:hover": {
            backgroundColor: colors.yellowHover,
            color: "#000",
          },
          "& .MuiTouchRipple-root": {
            color: "#d69e00",
            top: -1,
            left: -1,
            bottom: -1,
            right: -1,
          },
        },
        outlinedSecondary: {
          color: "#000",
          backgroundColor: "transparent",
          border: `2px solid ${colors.greyLight}`,
          "&:hover": {
            backgroundColor: colors.yellowHover,
            border: `2px solid ${colors.yellowHover}`,
          },
          "& .MuiTouchRipple-root": {
            color: "#d69e00",
            top: -1,
            left: -1,
            bottom: -1,
            right: -1,
          },
        },
        sizeSmall: {
          height: spacing * 3,
          padding: `0px ${spacing * 2}px`,
          ...body2,
        },
        sizeLarge: {
          height: spacing * 4,
          padding: `0px ${spacing * 4}px`,
          ...body1,
        },
      },
      MuiTab: {
        textColorPrimary: {
          color: colors.blackLight,
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: "14px",
        },
      },
      MuiDialog: {
        paper: {
          "@media (max-width:600px)": {
            margin: "5px",
          },
        },
      },
      MuiDialogContent: {
        dividers: {
          borderColor: "#e8e8e8",
        },
      },
    },
    props: {
      MuiGrid: { spacing: 2 }, //padding 12px
      MuiButton: {
        variant: "contained",
        size: "small",
        color: "primary",
        tabIndex: undefined,
      },
      MuiSvgIcon: { ...{ role: "img" } }, //for w3 validator
    },
    mixins: {
      toolbar: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  })
}

export type TBnbTheme = Theme & { betnbet: typeof customTheme }

export const theme: TBnbTheme = {
  betnbet: customTheme,
  ...getMuiTheme(),
}

// new theme without mui
export type TTypographyName =
  | "h1"
  | "h1_article"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "lead"
  | "p1"
  | "p2"
  | "p3"
  | "p4"
  | "btsSmall"
  | "btsMediun"
  | "btsLarge"
  | "list"
  | "body1"
  | "body2"
  | "subtitle1"
  | "subtitle2"
type TTypography = Record<TTypographyName, CSSProperties>
const typography: TTypography = {
  h1: {
    fontFamily: "Montserrat",
    fontSize: "28px",
    lineHeight: "32px",
    fontWeight: 700,
    [smUp]: {
      fontSize: "42px",
      lineHeight: "48px",
    },
  },
  h1_article: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 700,
    [smUp]: {
      fontSize: "42px",
      lineHeight: "48px",
    },
  },
  h2: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    lineHeight: "30px",
    fontWeight: 700,
    [smUp]: {
      fontSize: "32px",
      lineHeight: "38px",
    },
  },
  h3: {
    fontFamily: "Montserrat",
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: 700,
    [smUp]: {
      fontSize: "24px",
      lineHeight: "30px",
    },
  },
  h4: {
    fontFamily: "Montserrat",
    fontSize: "17px",
    lineHeight: "23px",
    fontWeight: 700,
    [smUp]: {
      fontSize: "18px",
      lineHeight: "24px",
    },
  },
  h5: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  h6: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 600,
  },
  lead: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    lineHeight: "27px",
    [smUp]: {
      fontSize: "22px",
      lineHeight: "32px",
    },
  },
  list: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "25px",
    [smUp]: {
      fontSize: "18px",
      lineHeight: "27px",
    },
  },
  p1: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    lineHeight: "27px",
    [smUp]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },
  p2: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "25px",
    [smUp]: {
      fontSize: "18px",
      lineHeight: "27px",
    },
  },
  p3: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "21px",
    [smUp]: {
      fontSize: "14px",
      lineHeight: "21px",
    },
  },
  p4: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    lineHeight: "17px",
    [smUp]: {
      fontSize: "12px",
      lineHeight: "17px",
    },
  },
  btsSmall: {
    fontFamily: "Montserrat",
    fontSize: "10px",
    lineHeight: "18px",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  btsMediun: {
    fontFamily: "Montserrat",
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  btsLarge: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  body1: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: 400,
  },
  body2: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: 400,
  },
}

type TColorName =
  | "primary"
  | "secondary"
  | "dark"
  | "light"
  | "success"
  | "warning"
  | "danger"
type TColor = Record<TColorName, Record<number, string>>
const color: TColor = {
  primary: {
    1: "#FFD050",
    2: "#FEE8AD",
    3: "#FFF6DE",
  },
  secondary: {
    1: "#305DFC",
    2: "#99AEFA",
    3: "#EAEFFE",
  },
  dark: {
    1: "#000",
    2: "#8D8D8D",
    3: "#D1D1D1",
    4: "#E6E6E6",
    5: "#616161",
  },
  light: {
    1: "#FFFFFF",
    2: "#F7F8F9",
  },
  success: {
    1: "#3EB25B",
    2: "#A0D7AF",
    3: "#D8EFDF",
  },
  warning: {
    1: "#FFC042",
    2: "#FEE1A6",
    3: "#FFF2DB",
  },
  danger: {
    1: "#FA2538",
    2: "#FB979E",
    3: "#FDD4D7",
  },
}

type TBnbCustomTheme = {
  typography: TTypography
  colors: TColor
  size: typeof size
  gap: typeof gap
  breakpoints: Omit<Breakpoints, "up" | "down" | "between" | "only"> & {
    up: (key: Breakpoint | number) => any
    down: (key: Breakpoint | number) => any
    between: (start: Breakpoint | number, end: Breakpoint | number) => any
    only: (key: Breakpoint) => any
  }
}

export const bnbTheme: TBnbCustomTheme = {
  typography,
  colors: color,
  size,
  gap,
  breakpoints: createBreakpoints({}),
}
