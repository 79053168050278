import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import React, { FC, useEffect } from "react"
import { Link } from "react-router-dom"
import { useDi } from "../App/di"
import { CommentsAvatar } from "../comments/comments-avatar"
import { Img } from "../common/img"
import { Typography } from "../common/typography"
import { ENotificationTypes, TLayoutBonus, TMeNotification } from "../payload"
import { ERouteUrl } from "../routes/routes-config"
import { Icon } from "../slider/icons"
import { TBnbTheme, bnbTheme } from "../theme"

type TNotificationsMenu = {
  closeMenu: () => void
  bonuses: TLayoutBonus[]
  isOpen: boolean
}
const NotificationsMenu: FC<TNotificationsMenu> = ({
  closeMenu,
  bonuses,
  isOpen,
}) => {
  const cs = useStyles()
  const {
    store,
    history,
    // api,
    cs: { Header: c },
  } = useDi()
  const { auth } = store

  const readNotifications = async () => {
    if (!auth.userLoaded || !auth.user) return
    // try {
    //   const ids: string[] = auth.user.notifications
    //     .filter((n) => !n.is_read)
    //     .map((n) => n.id)
    //   const res = await api.readNotifications(null, ids)
    //   auth.setUser((prev) => ({
    //     ...prev!,
    //     notifications: res.notifications,
    //     nonReadableNotification: res.nonReadableNotification,
    //   }))
    //   auth.user.notifications = res.notifications
    // } catch (e) {
    //   console.log(e)
    // }
  }

  useEffect(() => {
    if (isOpen) {
      const unlistener = history.listen(() => closeMenu())
      return () => {
        readNotifications()
        unlistener()
      }
    }
  }, [isOpen])
  return (
    <>
      <div
        className={clsx(isOpen && cs.visible, cs.overlay)}
        onClick={closeMenu}
      >
        <div className={cs.closeMobile}>
          <Icon name="close" width={24} className={cs.closeIcon} />
        </div>

        <div className={clsx(isOpen && cs.visible, cs.overlayGrey)} />
      </div>
      <div
        className={clsx(
          c.popup,
          isOpen && c.visible,
          bonuses.length > 0 && cs.redTriangle
        )}
      >
        <ul className={clsx(cs.overflow)}>
          {bonuses.map((bonus) => (
            <li key={bonus.link}>
              <Bonus bonus={bonus} key={bonus.text} />
            </li>
          ))}
        </ul>
        {auth.userLoaded && (
          <div className={cs.scroll}>
            <UserNotifications />
          </div>
        )}
      </div>
    </>
  )
}
export default NotificationsMenu

const UserNotifications: FC = () => {
  const cs = useStyles()
  const {
    store: { auth },
    lang,
  } = useDi()
  if (!auth.user) return null
  const nots = auth.user.notifications
  return (
    <>
      <Link className={cs.settings} to={ERouteUrl.notifications}>
        <Typography name="btsMediun">
          {nots.length > 0
            ? `${lang.notifications_menu__user_notifications} (${nots.length})`
            : lang.notifications_menu__user_notifications__no_notifications}
        </Typography>
        <Icon name="settings" width={20} className={cs.settingsIcon} />
      </Link>
      {nots.map((n) => (
        <Notification notification={n} key={n.id} />
      ))}
    </>
  )
}

const useStyles = makeStyles(
  (theme: TBnbTheme) => {
    return {
      overlay: {
        visibility: "hidden",
        "@media (max-width:600px)": {
          display: "block",
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 9,
        },
      },
      overlayGrey: {
        visibility: "hidden",
        "@media (max-width:600px)": {
          background: "#000",
          marginTop: bnbTheme.size.headerHeight,
          height: "100%",
          opacity: 0.5,
        },
      },
      visible: {
        visibility: "visible",
      },
      closeMobile: {
        height: bnbTheme.size.headerHeight,
        display: "flex",
        background: "#fff",
        position: "absolute",
        right: 0,
        top: 0,
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        "@media (min-width:600px)": {
          display: "none",
        },
      },
      closeIcon: {
        marginRight: 12,
      },
      overflow: {
        overflow: "hidden",
        listStyle: "none",
        paddingLeft: 0,
        margin: "0",
        maxHeight: "calc(100vh - 48px)",
        // minHeight: "calc(100vh - 48px)",
        display: "grid",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
        gridTemplateRows: "auto 1fr",
        "@media (min-width:600px)": {
          borderRadius: 8,
          maxHeight: "initial",
          minHeight: "initial",
        },
      },
      redTriangle: {
        [theme.breakpoints.up("sm")]: {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)",
        },
        "&:before": { background: "#FFF1F1 !important" },
      },
      scroll: {
        overflowY: "auto",
        overflowX: "hidden",
        //scroll
        "&::-webkit-scrollbar": { width: "6px" },
        "&::-webkit-scrollbar-track": {
          background: "#fff",
          borderRadius: 6,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: bnbTheme.colors.dark[4],
          borderRadius: "4px",
          width: 4,
        },
        //for firefox
        scrollbarColor: `${bnbTheme.colors.dark[4]} #fff`,
        scrollbarWidth: "thin",
        [theme.breakpoints.up("sm")]: {
          maxHeight: 616,
          minHeight: "auto",
          borderRadius: 8,
          "& > div:last-child": { paddingBottom: 20 },
        },
      },
      settings: {
        padding: "18px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: bnbTheme.colors.dark[2],

        background: "#fff",
        [theme.breakpoints.up("sm")]: {
          padding: "16px",
          "&:hover svg": { fill: bnbTheme.colors.primary[1] },
        },
      },
      settingsIcon: {
        fill: bnbTheme.colors.dark[3],
        width: 24,
        height: 24,
        [theme.breakpoints.up("sm")]: {
          width: 20,
          height: 20,
        },
      },
    }
  },
  { name: "NotificationsMenu" }
)

type TBonus = { bonus: TLayoutBonus }
const Bonus: FC<TBonus> = ({ bonus }) => {
  const { lang } = useDi()
  const cs = useBonusStyles()
  return (
    <a
      className={cs.root}
      href={bonus.link}
      // target="_blank"
      itemScope
    >
      <Img
        src={bonus.bookmaker_logo}
        className={cs.logo}
        width={93}
        imgAttr={{ width: 93, height: 31 }}
        alt={bonus.bookmaker_name}
      />
      {bonus.pixel && (
        <img
          src={bonus.pixel}
          width={1}
          height={1}
          style={{ display: "none" }}
        />
      )}
      <div className={cs.text}>{bonus.text}</div>
      <div className={cs.button}>{lang.button__get}</div>
    </a>
  )
}

const useBonusStyles = makeStyles(
  (theme: TBnbTheme) => {
    return {
      root: {
        padding: 16,
        background: "#FFF1F1",
        display: "grid",
        gridTemplateAreas: `
        "logo button"
        "text button"
        `,
        alignItems: "center",
        gridTemplateColumns: "1fr 111px",
        [theme.breakpoints.up("sm")]: {
          gridTemplateAreas: `
          "logo text button"
          `,
          gridTemplateColumns: "93px 1fr 111px",
          gridGap: 10,
        },
      },
      logo: {
        gridArea: "logo",
        width: 93,
      },
      text: {
        gridArea: "text",
        ...(bnbTheme.typography.h6 as any),
        fontSize: "12px",
        lineHeight: "16px",
        [theme.breakpoints.up("sm")]: {
          fontSize: 14,
          lineHeight: "20px",
        },
      },
      button: {
        gridArea: "button",
        ...(bnbTheme.typography.btsMediun as any),
        height: 40,
        padding: "0 16px",
        display: "flex",
        alignItems: "center",
        borderRadius: 8,
        backgroundColor: bnbTheme.colors.primary[1],
        transition: "150ms",
        "&:hover": {
          background: bnbTheme.colors.primary[2],
        },
      },
    }
  },
  { name: "BonusDD" }
)

type TNotification = {
  notification: TMeNotification
}

const Notification: FC<TNotification> = (props) => {
  const { notification: n } = props
  const { lang } = useDi()
  const cs = useNotificationStyles()
  if (n.type === ENotificationTypes.comment) {
    return (
      <div className={clsx(cs.root, !n.is_read && cs.new)}>
        <CommentsAvatar
          width={32}
          src={n.author_avatar}
          className={cs.ava}
          name={n.author_nickname}
        />
        <Typography name="h5" className={cs.text}>
          <span className={cs.from}>{n.author_nickname + " "}</span>
          <span className={cs.text}>
            {" " + lang.notification__commented_on_the_article + " "}
          </span>
          <Link to={n.article_url} className={cs.link}>
            {" " + n.article_title}
          </Link>
        </Typography>
        <Typography name="btsMediun" className={cs.date}>
          {n.created_at}
        </Typography>
      </div>
    )
  }
  if (n.type === ENotificationTypes.answer) {
    return (
      <div className={clsx(cs.root, !n.is_read && cs.new)}>
        <CommentsAvatar
          width={32}
          src={n.commentator_avatar}
          className={cs.ava}
          name={n.commentator_nickname}
        />
        <Typography name="h5" className={cs.text}>
          <span className={cs.from}>{n.commentator_nickname}</span>
          <span className={cs.text}>
            {" " + lang.notification__replied_to_your_comment + " "}
          </span>
          <Link to={n.article_url} className={cs.link}>
            {n.article_title}
          </Link>
        </Typography>
        <Typography name="btsMediun" className={cs.date}>
          {n.created_at}
        </Typography>
      </div>
    )
  }
  return null
}
Notification.displayName = "Notification"

const useNotificationStyles = makeStyles(
  (theme: TBnbTheme) => ({
    root: {
      padding: 16,
      display: "grid",
      gridGap: 8,
      gridTemplateColumns: "32px 1fr",
      gridTemplateAreas: `
     "ava text"
     "ava date"
     `,
      background: "#fff",
      borderTop: `1px solid ${bnbTheme.colors.dark[4]}`,
      transition: ".2s",
      "&:hover": { background: bnbTheme.colors.light[2] },
    },
    ava: {
      gridArea: "ava",
      width: 32,
      height: 32,
    },
    text: {
      // color: theme.betnbet.colors.greyDark,
      gridArea: "text",
      fontWeight: 400,
    },
    date: {
      color: bnbTheme.colors.dark[3],
      gridArea: "date",
    },
    notif: {
      ...theme.typography.subtitle1,
    },
    from: {
      color: "#000",
      fontWeight: 700,
    },
    link: {
      color: bnbTheme.colors.secondary[1],
      "&:hover": { color: bnbTheme.colors.secondary[2] },
    },
    new: {
      background: "#F6F8FC",
    },
  }),
  { name: Notification.displayName }
)
