import { TWebRegionListItem } from "../payload"

export const excludeRegion = (
  region_list: TWebRegionListItem[],
  regionToExclude: string
) => {
  const regions = region_list.slice()
  const region_index = regions.findIndex(
    (region) => region.name === regionToExclude
  )
  if (region_index >= 0) {
    regions.splice(region_index, 1)
  }
  return regions
}
