import React, { FC } from "react"
import { useNavigation } from "react-router-dom"
import { useDi } from "../App/di"
import { LoaderProgress } from "../App/loader-progress"
import { asyncComponent } from "../el/chunk"
import { WidgetCatfish } from "../widget/widget-catfish"

const SnackBar = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "SnackBar" */
      /* webpackMode: "lazy" */
      "../snack/snack-bar"
    )
)

export const UserMenu = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "UserMenu" */
      /* webpackMode: "lazy" */
      "../header/header-user-menu"
    )
)

export const SearchPopup = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "SearchPopup" */
      /* webpackMode: "lazy" */
      "../search/search-models"
    )
)

export const Modal = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Modal" */
      /* webpackMode: "lazy" */
      "../common/modal"
    )
)

const Locker = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Locker" */
      /* webpackMode: "lazy" */
      "../common/page-loader"
    )
)

export const LayoutLazyComponents: FC = () => {
  const { store } = useDi()
  const { modal, pageLoader, snack } = store
  const navigation = useNavigation() // https://reactrouter.com/6.30.0/hooks/use-navigation
  let is_loading = navigation.state === "loading"
  return (
    <>
      <WidgetCatfish />
      {snack.current && <SnackBar />}
      {pageLoader.isLoading && <Locker />}
      <LoaderProgress isLoading={is_loading} />
      {modal.show && <Modal />}
    </>
  )
}
