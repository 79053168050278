import { History } from "history"
import { createContext, useContext } from "react"
import { TEnv } from "../App/AppEnv"
import { TApi } from "../graphql/graphql-sdk"
import { TLang, TLng } from "../lang"
import { TWebRegion, TWebRegionListItem } from "../payload"
import { TStore } from "../store/store"
import { TBnbTheme } from "../theme"
import { TStyleGroups } from "./styles"

export type TDeps = {
  theme: TBnbTheme
  history: History
  env: TEnv
  api: TApi
  cs: TStyleGroups
  region: TWebRegion
  region_list: TWebRegionListItem[]
  store: TStore
  user_country_iso: string
  lang: TLang
  lng: TLng
}

export type TDepsSsr = Omit<TDeps, "store">

export const DepsContext = createContext<TDeps>(null as any as TDeps)
export const useDi = (): TDeps => useContext(DepsContext)
