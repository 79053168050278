import { asyncComponent } from "../el/chunk"
import { allowIntersectionObserver } from "@betnbet/front-sdk/dist/util/lazy-show"
import React, { createElement, FC, useEffect, useRef, useState } from "react"
import { useDi } from "../App/di"
import { probability } from "../common/probability"
import { TWidgetCatfishData } from "../payload"
import { TWidgetComponentProps } from "./widget-config"

const Catfish = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "WidgetCatfish" */
      /* webpackMode: "lazy" */
      "./widget-catfish-el"
    )
)

type TWidgetRoot = {}
export const WidgetCatfish: FC<TWidgetRoot> = () => {
  const { store } = useDi()
  const { widget } = store
  const [isRendered, toRender] = useState<boolean>(true)

  if (!isRendered || !widget.catfish.show) return null

  const close = (animation: React.Dispatch<React.SetStateAction<boolean>>) => {
    animation(false) // delay 200ms
    setTimeout(() => toRender(false), 120) // wait for animation end
  }
  return <WidgetScrollChecker close={close} />
}

type TBannerRoot = {
  close: (animation: React.Dispatch<React.SetStateAction<boolean>>) => void
}

const WidgetScrollChecker: FC<TBannerRoot> = ({ close }) => {
  const { api, store } = useDi()
  const { widget } = store

  const firstLoading = useRef(true)
  const [widgets, setWidgets] = useState<TWidgetCatfishData[]>([])
  const [catfish, setCatfish] = useState<TWidgetCatfishData | null>(null)
  const [animationUp, setAnumationUp] = useState<boolean>(false)

  useEffect(() => {
    if (firstLoading.current || widgets.length === 0) return

    if (typeof widget.catfish.bkSlug === "undefined") {
      const theOne = calcProb(widgets)
      return setCatfish(theOne)
    }

    if (typeof widget.catfish.bkSlug === "string") {
      const filterWidgets = widgets.filter(
        (w) => w.bookmaker_slug === widget.catfish.bkSlug
      )
      if (filterWidgets.length === 0) {
        return setCatfish(null)
      }
      const theOneFromBk = calcProb(filterWidgets)
      return setCatfish(theOneFromBk)
    }
  }, [widget.catfish.bkSlug, firstLoading.current])

  useEffect(() => {
    const loadJs = async () => {
      const all = await api.widgetsCatfish()
      setWidgets(all)
      await Catfish.load()
      firstLoading.current = false
    }
    if (!allowIntersectionObserver) return
    const triggerEl = document.getElementById("catfish-trigger")
    if (!triggerEl) return
    const observer = new IntersectionObserver(
      async ([e]) => {
        const timeToShow = !e.isIntersecting
        if (firstLoading.current && timeToShow) {
          await loadJs()
        }
        setAnumationUp(timeToShow)
      },
      { threshold: [0] }
    )

    observer.observe(triggerEl)
    return () => observer.unobserve(triggerEl)
  }, [])

  return firstLoading.current || !catfish
    ? null
    : createElement(Catfish, {
        close: () => close(setAnumationUp),
        show: animationUp,
        widget: catfish,
      } as TWidgetComponentProps as any)
}

const calcProb = (arr: TWidgetCatfishData[]): TWidgetCatfishData => {
  const rotation_sum = arr.reduce((acc, w) => acc + w.rotation, 0)
  return probability(
    arr.map((w) => ({
      p: w.rotation / rotation_sum,
      f: () => w,
    }))
  )
}
