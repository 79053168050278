import { proxyApi } from "@betnbet/front-sdk/dist/auth"
import { errorLog } from "@betnbet/front-sdk/dist/util/error"
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles"
import { createBrowserHistory, Location } from "history"
import { create } from "jss"
import React, { FC, useEffect, useState } from "react"
import { hydrate, render } from "react-dom"
import { matchRoutes, Router, RouterProvider } from "react-router"
import { getEnv } from "./App/AppEnv"
import { DepsContext, TDeps, TDepsSsr } from "./App/di"
import { TCsRecord, TStyleGroups } from "./App/styles"
import { ErrorBoundary } from "./common/error-boundary"
import { api } from "./graphql/api-browser"
import { TApi } from "./graphql/graphql-sdk"
import { TMe, TSsrState } from "./payload"
import { TWidgetConfig, useStore } from "./store/store"
import { theme } from "./theme"
import { createBrowserRouter } from "react-router-dom"
import { get_routes } from "./routes/routes"

// const authRetryApi = proxyApi<TApi>(api)

const cs = new Proxy({} as TStyleGroups, {
  get: function (_target, componentName: keyof TStyleGroups, _receiver) {
    if (_target[componentName]) return _target[componentName]
    const p = new Proxy({} as TCsRecord<string>, {
      get: function (_target, name: string, _receiver) {
        return componentName + "_" + name
      },
    })
    _target[componentName] = p as any
    return p
  },
})

const init = async () => {
  const history = createBrowserHistory()
  const env = getEnv()
  // const user = env.AUTH ? await authRetryApi.me() : undefined

  const deps: TDepsSsr = {
    theme,
    env,
    // api: authRetryApi,
    api,
    history,
    cs,
    region: window.ssr_state.region,
    region_list: window.ssr_state.region_list,
    user_country_iso: window.ssr_state.user_country_iso,
    lang: window.ssr_state.lang,
    lng: window.ssr_state.lng,
  }


  const el = document.getElementById("app")
  if (!el) throw new Error("no el")

  const generateClassName = createGenerateClassName()
  const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById("jss-append")!,
  })
  let routes = get_routes(api, deps.region.name);

  // https://github.com/remix-run/react-router/blob/v6/examples/ssr-data-router/src/entry.client.tsx
  // Determine if any of the initial routes are lazy
  let lazyMatches = matchRoutes(routes, window.location)?.filter(
    (m) => m.route.lazy
  );
  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        let routeModule = await m.route.lazy!();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      })
    );
  }

  const router = createBrowserRouter(routes);
  const renderer = el.childNodes.length === 0 ? render : hydrate
  renderer(
    <StylesProvider generateClassName={generateClassName} jss={jss}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary log={errorLog}>
          <Browser
            router={router}
            deps={deps}
            auth0_domain={env.AUTH0_DOMAIN}
            auth0_client_id={env.AUTH0_CLIENT_ID}
          />
        </ErrorBoundary>
      </ThemeProvider>
    </StylesProvider>,
    el
  )

  const jssEl = document.getElementById("jss")
  jssEl?.parentNode?.removeChild(jssEl)
  history.listen(() => window.ga && window.ga("send", "pageview"))
}

declare global {
  interface Window {
    ssr_state: TSsrState
  }
}

type TAppRootBrowserProps = {
  router: ReturnType<typeof createBrowserRouter>
  deps: TDepsSsr
  // widget: TWidgetConfig | undefined
  // user: TMe | undefined
  auth0_domain: string
  auth0_client_id: string
}

const Browser: FC<TAppRootBrowserProps> = (props) => {
  const { router, deps,  auth0_domain, auth0_client_id } = props

  // const depsWithStore: TDeps = {
  //   ...deps,
  //   store: useStore({
  //     widget: window.ssr_state.widget || widget,
  //     user,
  //     // auth0_domain,
  //     // auth0_client_id,
  //   }),
  // }
  const depsWithStore: TDeps = {
    ...deps,
    store: useStore({
      widget: window.ssr_state.widget,
      // widget: window.ssr_state.widget || widget,
      // user,
      auth0_domain: auth0_domain,
      auth0_client_id: auth0_client_id,
    }),
  }

  return (
    <DepsContext.Provider value={depsWithStore}>
      <RouterProvider router={router} />
    </DepsContext.Provider>
  )
}

init()
