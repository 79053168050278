import clsx from "clsx"
import React, { FC } from "react"
import { useDi } from "../App/di"
import { html_flags } from "../const/regions"
import { THrefLang } from "../layout/layout"
// import ReactSelect from 'react-select';
import { Select } from "../common/select"

type TFooterRegionToggle = { className?: string; hreflangs: THrefLang[] }
export const FooterRegionToggle: FC<TFooterRegionToggle> = ({
  className,
  hreflangs,
}) => {
  const {
    region,
    region_list,
    lang,
    cs: { Footer: cs },
  } = useDi()

  const current_region = region_list.find((r) => r.domain === region.domain)
  if (!current_region) return null
  const rest_regions = region_list.filter(
    (r) => r.domain !== region.domain && !r.is_hidden
  )
  let options = rest_regions.map((r) => {
    let hl = hreflangs.find((hl) => hl.domain === r.domain)
    let href = hl ? hl.href : "https://" + r.domain
    let flag = html_flags[r.name] || ""
    return {
      label: flag + " " + r.country,
      value: r.hreflang,
      href: href,
    }
  })
  let flag = html_flags[current_region.name] || ""
  return (
    <Select
      className={clsx(cs.region_toggle_root, className)}
      options={options}
      value={{
        label: flag + " " + current_region.country,
        value: current_region.hreflang,
        // svg_name: current_region.name as RegionNames,
        aria_label: lang.footer__region_toggle__label,
      }}
    />
  )
  // let value = options.find((o) => o.value === current_region.hreflang);
  // return (
  //   <ReactSelect
  //    onChange={event => event && history.push(event.href)}
  //     options={options}
  //     defaultValue={value}
  //     className={clsx(cs.root, className)}
  //     menuPlacement="auto"
  //     filterOption={(option) => option.value !== current_region.hreflang}
  //     isSearchable={false}
  //   />
  // )
}
