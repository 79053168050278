export enum RegionNames {
  RU = "ru",
  UA = "ua",
  BY = "by",
  KZ = "kz",
  TJ = "tj",
  UZ = "uz",
  MX = "mx",
}

type THtmlFlags = Record<string, string>
export const html_flags: THtmlFlags = {
  by: "🇧🇾",
  kz: "🇰🇿",
  mx: "🇲🇽",
  ru: "🇷🇺",
  tj: "🇹🇯",
  ua: "🇺🇦",
  uz: "🇺🇿",
}
