import LinearProgress from "@material-ui/core/LinearProgress"
import React, { FC, useEffect, useState } from "react"
import { useDi } from "./di"

export type TLoaderProgressCs = "root" | "bar"

export const LoaderProgress: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const {
    cs: { LoaderProgress: classes },
  } = useDi()
  const minValue = 0
  const maxValue = 100
  const waitingValue = 60
  const [value, setValue] = useState<number>(minValue) // %
  const [isVisible, setVisible] = useState<boolean>(false) // hide progress on 1st loading

  const progress = () => {
    setValue((oldValue) => {
      //if value===100 stop progress, hide it and set value=0
      if (oldValue >= maxValue) return maxValue

      //if value===60 stop progress and wait
      if (oldValue === waitingValue) return waitingValue

      return oldValue + 20
    })
  }

  useEffect(() => {
    const timer = setTimeout(progress, 150)
    if (value === maxValue) setTimeout(() => setVisible(false), 500)
    return () => clearTimeout(timer)
  }, [value])

  useEffect(() => {
    if (isLoading) {
      setVisible(true)
      setValue(minValue)
    } else {
      setValue(maxValue)
    }
  }, [isLoading])

  return (
    <>
      {isVisible && (
        <LinearProgress
          variant="determinate"
          value={value}
          classes={{ root: classes.root, bar: classes.bar }}
        />
      )}
    </>
  )
}
LoaderProgress.displayName = "LoaderProgress"
