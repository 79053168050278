import React, { FC } from "react"

export const svgPath = {
  arrow: (
    <path d="M8.96967 4.71967C8.67678 5.01256 8.67678 5.48744 8.96967 5.78033L15.1893 12L8.96967 18.2197C8.67678 18.5126 8.67678 18.9874 8.96967 19.2803C9.26256 19.5732 9.73744 19.5732 10.0303 19.2803L16.7803 12.5303C17.0732 12.2374 17.0732 11.7626 16.7803 11.4697L10.0303 4.71967C9.73744 4.42678 9.26256 4.42678 8.96967 4.71967Z" />
  ),
  exit: (
    <path d="M12 4.35417V4.99999L13.7453 5C14.1245 5 14.4381 5.28152 14.4883 5.64712L14.4953 5.74876L14.502 10H13.002L12.9965 6.5L12 6.49999L12.0005 11.0049L13.002 11.004L13.003 11H14.504L14.503 11.004H19.442L17.7196 9.28024C17.4534 9.01393 17.4292 8.59726 17.6471 8.30369L17.7198 8.21958C17.9861 7.95336 18.4027 7.92923 18.6963 8.14713L18.7804 8.21976L21.777 11.2174C22.043 11.4835 22.0674 11.8997 21.85 12.1933L21.7775 12.2774L18.7809 15.2808C18.4884 15.574 18.0135 15.5746 17.7203 15.282C17.4537 15.0161 17.429 14.5994 17.6465 14.3056L17.7191 14.2214L19.432 12.504L12.0005 12.5049L12 17.001L13.0139 17.0015L13.007 13.5H14.508L14.5152 17.7502C14.5158 18.1303 14.2335 18.4448 13.867 18.4946L13.7652 18.5015L12 18.501V19.25C12 19.7164 11.5788 20.0697 11.1196 19.9886L2.61955 18.4873C2.26121 18.424 2 18.1126 2 17.7487V5.75C2 5.3827 2.26601 5.06944 2.62847 5.00992L11.1285 3.61408C11.5851 3.5391 12 3.89144 12 4.35417ZM10.5 5.23738L3.5 6.38689V17.1196L10.5 18.3559V5.23738ZM8.50215 11.5C9.05562 11.5 9.5043 11.9487 9.5043 12.5022C9.5043 13.0556 9.05562 13.5043 8.50215 13.5043C7.94868 13.5043 7.5 13.0556 7.5 12.5022C7.5 11.9487 7.94868 11.5 8.50215 11.5Z" />
  ),
  refresh: (
    <path d="M18.6195 3.31842C19.008 3.31842 19.3291 3.6071 19.3799 3.98164L19.3869 4.08577V6.97963C19.3869 7.36811 19.0982 7.68916 18.7237 7.73997L18.6195 7.74698H15.7257C15.3019 7.74698 14.9583 7.40342 14.9583 6.97963C14.9583 6.59115 15.247 6.27009 15.6215 6.21928L15.7257 6.21228L16.7044 6.21182C13.7917 3.87107 9.52213 4.05209 6.81934 6.75488C3.92254 9.65168 3.92254 14.3483 6.81934 17.2451C9.71614 20.1419 14.4128 20.1419 17.3096 17.2451C19.0725 15.4822 19.8107 12.9926 19.3476 10.5892C19.2674 10.1731 19.5398 9.77076 19.9559 9.69058C20.3721 9.61041 20.7744 9.88277 20.8546 10.2989C21.413 13.1972 20.5217 16.2034 18.3948 18.3303C14.8986 21.8265 9.23028 21.8265 5.73414 18.3303C2.238 14.8342 2.238 9.16582 5.73414 5.66968C9.05475 2.34907 14.3349 2.18235 17.8523 5.16954L17.8522 4.08577C17.8522 3.66197 18.1957 3.31842 18.6195 3.31842Z" />
  ),
  refreshBold: (
    <path d="M18.902 3.99317V7.43712C18.902 7.9751 18.4659 8.41122 17.9279 8.41122H14.484C13.946 8.41122 13.5099 7.9751 13.5099 7.43712C13.5099 6.89914 13.946 6.46303 14.484 6.46303L15.2846 6.46202C12.6657 4.89553 9.22232 5.24026 6.96637 7.49621C4.30351 10.1591 4.30351 14.4764 6.96637 17.1393C9.62923 19.8021 13.9466 19.8021 16.6094 17.1393C18.2302 15.5186 18.9086 13.2303 18.4829 11.0207C18.3811 10.4924 18.7268 9.98167 19.2551 9.87989C19.7834 9.77812 20.2941 10.1239 20.3959 10.6521C20.9426 13.49 20.0698 16.4341 17.987 18.5169C14.5633 21.9405 9.01247 21.9405 5.58879 18.5169C2.16512 15.0932 2.16512 9.54231 5.58879 6.11863C8.68616 3.02126 13.5246 2.72606 16.9538 5.233L16.9538 3.99317C16.9538 3.45519 17.39 3.01907 17.9279 3.01907C18.4659 3.01907 18.902 3.45519 18.902 3.99317Z" />
  ),
  search: (
    <path d="M10 2.75C14.0041 2.75 17.25 5.99594 17.25 10C17.25 11.7319 16.6427 13.3219 15.6295 14.5688L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2641 20.7966 19.8474 20.8208 19.5538 20.6029L19.4697 20.5303L14.5688 15.6295C13.3219 16.6427 11.7319 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 5.99594 5.99594 2.75 10 2.75ZM10 4.25C6.82436 4.25 4.25 6.82436 4.25 10C4.25 13.1756 6.82436 15.75 10 15.75C13.1756 15.75 15.75 13.1756 15.75 10C15.75 6.82436 13.1756 4.25 10 4.25Z" />
  ),
  arrowRight: (
    <path d="M13.2673 4.20926C12.9674 3.92357 12.4926 3.93511 12.2069 4.23504C11.9213 4.53497 11.9328 5.0097 12.2327 5.29539L18.4841 11.25H3.75C3.33579 11.25 3 11.5858 3 12C3 12.4142 3.33579 12.75 3.75 12.75H18.4842L12.2327 18.7047C11.9328 18.9904 11.9213 19.4651 12.2069 19.7651C12.4926 20.065 12.9674 20.0765 13.2673 19.7908L20.6862 12.7241C20.8551 12.5632 20.9551 12.358 20.9861 12.1446C20.9952 12.0978 21 12.0495 21 12C21 11.9504 20.9952 11.902 20.986 11.8551C20.955 11.6419 20.855 11.4368 20.6862 11.276L13.2673 4.20926Z" />
  ),
  burger: (
    <path d="M2.75257 18H21.2526C21.6668 18 22.0026 18.3358 22.0026 18.75C22.0026 19.1297 21.7204 19.4435 21.3543 19.4931L21.2526 19.5H2.75257C2.33835 19.5 2.00257 19.1642 2.00257 18.75C2.00257 18.3703 2.28472 18.0565 2.6508 18.0068L2.75257 18H21.2526H2.75257ZM2.75257 11.503H21.2526C21.6668 11.503 22.0026 11.8388 22.0026 12.253C22.0026 12.6327 21.7204 12.9465 21.3543 12.9962L21.2526 13.003H2.75257C2.33835 13.003 2.00257 12.6672 2.00257 12.253C2.00257 11.8733 2.28472 11.5595 2.6508 11.5098L2.75257 11.503H21.2526H2.75257ZM2.75171 5.00323H21.2517C21.6659 5.00323 22.0017 5.33902 22.0017 5.75323C22.0017 6.13293 21.7196 6.44673 21.3535 6.49639L21.2517 6.50323H2.75171C2.3375 6.50323 2.00171 6.16745 2.00171 5.75323C2.00171 5.37354 2.28386 5.05974 2.64994 5.01008L2.75171 5.00323H21.2517H2.75171Z" />
  ),
  redirect2: (
    <path d="M7.25007 4.5H10.7495C11.1637 4.5 11.4995 4.83579 11.4995 5.25C11.4995 5.6297 11.2173 5.94349 10.8512 5.99315L10.7495 6H7.24971C6.07076 5.99944 5.10346 6.90639 5.00783 8.06095L5.00025 8.21986L5.00309 16.7505C5.0034 17.9414 5.92882 18.9159 7.09963 18.9948L7.25368 18.9999L15.7517 18.9882C16.9415 18.9866 17.9145 18.0618 17.9935 16.8921L17.9987 16.7382V13.2319C17.9987 12.8177 18.3344 12.4819 18.7487 12.4819C19.1284 12.4819 19.4421 12.7641 19.4918 13.1302L19.4987 13.2319V16.7382C19.4987 18.7405 17.9292 20.3767 15.9528 20.4828L15.7538 20.4882L7.25824 20.4999L7.05492 20.4948C5.14236 20.3952 3.60892 18.8626 3.50834 16.95L3.50309 16.7509L3.50085 8.25253L3.50526 8.05003C3.60536 6.13732 5.13864 4.60432 7.05093 4.50511L7.25007 4.5H10.7495H7.25007ZM13.7481 3.0013L20.3018 3.0018L20.4013 3.01558L20.5021 3.04376L20.559 3.06787C20.6122 3.09105 20.6633 3.12146 20.711 3.15868L20.7804 3.22139L20.8641 3.31997L20.9183 3.41009L20.957 3.5004L20.9762 3.56459L20.9898 3.62845L20.9991 3.72265L20.9996 10.2552C20.9996 10.6694 20.6638 11.0052 20.2496 11.0052C19.8699 11.0052 19.5561 10.723 19.5065 10.357L19.4996 10.2552L19.4989 5.5613L12.2796 12.7845C12.0134 13.0508 11.5968 13.0751 11.3031 12.8574L11.219 12.7848C10.9526 12.5186 10.9283 12.1019 11.1461 11.8082L11.2187 11.7241L18.4369 4.5013H13.7481C13.3684 4.5013 13.0546 4.21914 13.0049 3.85307L12.9981 3.7513C12.9981 3.3716 13.2802 3.05781 13.6463 3.00814L13.7481 3.0013Z" />
  ),
  gift: (
    <path d="M11.25 13V22H7.25C5.45507 22 4 20.5449 4 18.75V13H11.25ZM20 13V18.75C20 20.5449 18.5449 22 16.75 22H12.75V13H20ZM14.5 2C16.2949 2 17.75 3.45507 17.75 5.25C17.75 5.89498 17.5621 6.49607 17.2381 7.00154L19.75 7C20.4404 7 21 7.46637 21 8.04167V10.9583C21 11.5336 20.4404 12 19.75 12L12.75 11.999V7H11.25V11.999L4.25 12C3.55964 12 3 11.5336 3 10.9583V8.04167C3 7.46637 3.55964 7 4.25 7L6.7619 7.00154C6.43788 6.49607 6.25 5.89498 6.25 5.25C6.25 3.45507 7.70507 2 9.5 2C10.5055 2 11.4044 2.45666 12.0006 3.17391C12.5956 2.45666 13.4945 2 14.5 2ZM9.5 3.5C8.5335 3.5 7.75 4.2835 7.75 5.25C7.75 6.16817 8.45711 6.92119 9.35647 6.9942L9.5 7H11.25V5.25L11.2442 5.10647C11.1712 4.20711 10.4182 3.5 9.5 3.5ZM14.5 3.5C13.5335 3.5 12.75 4.2835 12.75 5.25V7H14.5C15.4665 7 16.25 6.2165 16.25 5.25C16.25 4.2835 15.4665 3.5 14.5 3.5Z" />
  ),
  person: (
    <path d="M17.7543 13.9999C18.9963 13.9999 20.0032 15.0068 20.0032 16.2488V17.1673C20.0032 17.7406 19.8239 18.2996 19.4906 18.7661C17.9447 20.9294 15.4204 22.0011 12.0001 22.0011C8.57915 22.0011 6.05619 20.9289 4.51403 18.7646C4.18207 18.2987 4.00366 17.7408 4.00366 17.1688V16.2488C4.00366 15.0068 5.01052 13.9999 6.25254 13.9999H17.7543ZM12.0001 2.00462C14.7615 2.00462 17.0001 4.2432 17.0001 7.00462C17.0001 9.76605 14.7615 12.0046 12.0001 12.0046C9.2387 12.0046 7.00012 9.76605 7.00012 7.00462C7.00012 4.2432 9.2387 2.00462 12.0001 2.00462Z" />
  ),
  alert: (
    <path d="M9.04173 19.003H14.9583C14.7196 20.4211 13.486 21.5015 12 21.5015C10.514 21.5015 9.28037 20.4211 9.04173 19.003ZM12 2.00389C16.1421 2.00389 19.5 5.36176 19.5 9.50389V13.5024L20.9183 16.6625C20.9732 16.7848 21.0016 16.9174 21.0016 17.0515C21.0016 17.5762 20.5763 18.0015 20.0516 18.0015H3.95219C3.8184 18.0015 3.68613 17.9732 3.56402 17.9186C3.08515 17.7042 2.87073 17.1422 3.08511 16.6633L4.50001 13.5028L4.50011 9.49097L4.50453 9.24106C4.6436 5.21033 7.95588 2.00389 12 2.00389Z" />
  ),
  loader: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C13.2282 20.25 14.3917 19.9821 15.4372 19.5023C15.8136 19.3295 16.2589 19.4946 16.4316 19.8711C16.6044 20.2475 16.4393 20.6928 16.0628 20.8656C14.8251 21.4337 13.4485 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 12.8405 21.6435 13.6572 21.4428 14.4369C21.3395 14.8381 20.9307 15.0796 20.5295 14.9763C20.1284 14.8731 19.8869 14.4642 19.9901 14.0631C20.1596 13.4045 20.25 12.7133 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75Z"
        fill="url(#loader_gradient)"
      />
      <defs>
        <radialGradient
          id="loader_gradient"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22 0.5) rotate(87.1376) scale(20.025 43.9609)"
        >
          <stop stopColor="#305DFC" />
          <stop offset="0.588542" stopColor="#305DFC" />
          <stop offset="1" stopColor="#A8BBFF" />
        </radialGradient>
      </defs>
    </>
  ),
  star_filled: (
    <path d="M8.6436 8.20794L11.3272 2.75964C11.6021 2.2015 12.3979 2.2015 12.6728 2.75964L15.3563 8.20794L21.3672 9.07654C21.9828 9.16549 22.2289 9.92194 21.7834 10.356L17.4358 14.5921L18.4622 20.5779C18.5673 21.1908 17.9237 21.658 17.3734 21.3682L12 18.5378L6.6265 21.3682C6.07602 21.6582 5.4322 21.1904 5.53786 20.5773L6.56925 14.5921L2.21687 10.3563C1.771 9.92237 2.01692 9.16552 2.63269 9.07654L8.6436 8.20794Z" />
  ),
  star_outline: (
    <path d="M8.64 8.2l2.69-5.44a.75.75 0 011.34 0l2.69 5.45 6 .87c.62.09.87.84.42 1.28l-4.34 4.23 1.02 5.99a.75.75 0 01-1.09.79L12 18.54l-5.37 2.83a.75.75 0 01-1.1-.8l1.04-5.98-4.35-4.23a.75.75 0 01.41-1.28l6.01-.87zM12 4.8L9.81 9.23a.75.75 0 01-.56.4l-4.9.71L7.9 13.8c.18.18.26.42.21.67l-.84 4.87 4.38-2.3a.75.75 0 01.7 0l4.38 2.3-.84-4.87a.75.75 0 01.22-.67l3.54-3.45-4.9-.7a.75.75 0 01-.56-.41L12 4.79z" />
  ),
  star_outline_filled: (
    <>
      <path d="M8.64 8.2l2.69-5.44a.75.75 0 011.34 0l2.69 5.45 6 .87c.62.09.87.84.42 1.28l-4.34 4.23 1.02 5.99a.75.75 0 01-1.09.79L12 18.54l-5.37 2.83a.75.75 0 01-1.1-.8l1.04-5.98-4.35-4.23a.75.75 0 01.41-1.28l6.01-.87zM12 4.8L9.81 9.23a.75.75 0 01-.56.4l-4.9.71L7.9 13.8c.18.18.26.42.21.67l-.84 4.87 4.38-2.3a.75.75 0 01.7 0l4.38 2.3-.84-4.87a.75.75 0 01.22-.67l3.54-3.45-4.9-.7a.75.75 0 01-.56-.41L12 4.79z" />
      <path d="M12 4.79L9.81 9.23a.75.75 0 01-.56.4l-4.9.71L7.9 13.8c.18.18.26.42.21.67l-.84 4.87 4.38-2.3c.11-.06.23-.09.35-.09V4.79z" />
    </>
  ),
  football: (
    <path d="M12 2.4a9.61 9.61 0 000 19.2 9.61 9.61 0 000-19.2zm6.6 14.03h-2.09a.37.37 0 01-.32-.18l-.74-1.28a.37.37 0 01-.04-.28l.69-2.76a.37.37 0 01.21-.26l1.3-.54a.37.37 0 01.38.06l1.92 1.65a.37.37 0 01.13.33 8.06 8.06 0 01-1.12 3.08.37.37 0 01-.32.18zm-12.2-5.3l1.29.55a.37.37 0 01.21.25l.7 2.77c.01.09 0 .19-.05.27l-.74 1.28a.37.37 0 01-.32.18H5.4a.37.37 0 01-.31-.18 8.07 8.07 0 01-1.13-3.07.37.37 0 01.13-.34L6 11.2a.37.37 0 01.38-.06zM18.24 7.1l-.83 2.37a.37.37 0 01-.2.22l-1.37.58a.37.37 0 01-.38-.06l-2.6-2.17a.37.37 0 01-.13-.29V6.2a.37.37 0 01.16-.3l1.98-1.32a.37.37 0 01.35-.03 8.17 8.17 0 012.95 2.19.37.37 0 01.07.36zM9.12 4.58L11.1 5.9a.37.37 0 01.16.3v1.55a.37.37 0 01-.13.29l-2.6 2.17a.37.37 0 01-.38.06L6.78 9.7a.37.37 0 01-.2-.22L5.75 7.1a.37.37 0 01.07-.36c.8-.95 1.81-1.7 2.95-2.2a.37.37 0 01.35.04zm.8 15.11L9 17.4a.37.37 0 01.02-.32l.7-1.2a.37.37 0 01.32-.19h3.9a.37.37 0 01.32.19l.7 1.2a.37.37 0 01.02.32l-.9 2.3a.37.37 0 01-.26.22c-1.2.27-2.45.27-3.65 0a.37.37 0 01-.26-.23z" />
  ),
  basketball: (
    <path d="M11.74 10.8l1.46-1.57a9.83 9.83 0 01-2.1-6.83A8.48 8.48 0 006 4.64l5.74 6.16zM14.85 8.4L18 4.72a7.78 7.78 0 00-4.77-2.32 9.13 9.13 0 001.62 6zM15.6 9.15a9.13 9.13 0 006 1.62A7.78 7.78 0 0019.28 6L15.6 9.15zM8.4 14.85a9.13 9.13 0 00-6-1.62A7.78 7.78 0 004.72 18l3.68-3.15zM12.26 13.2l-1.46 1.57a9.83 9.83 0 012.1 6.83 8.48 8.48 0 005.1-2.24l-5.74-6.16zM20.92 12.93a9.83 9.83 0 01-6.15-2.13l-1.57 1.46L19.35 18a8.48 8.48 0 002.25-5.1l-.68.03zM10.8 11.74L4.65 6a8.48 8.48 0 00-2.25 5.1 9.82 9.82 0 016.83 2.1l1.57-1.46zM6 19.28a7.78 7.78 0 004.77 2.32 9.12 9.12 0 00-1.62-6L6 19.28z" />
  ),
  mma: (
    <>
      <path d="M10.24 10.97l-2.73-.93c-.08.18-.26.68-.3 1.3a9.4 9.4 0 00.15 2.2c.08.43.62.86.73.95.12.08 1.25.7 1.65 1.01.4.3.4.54.41.6.02.05.13 1.3.15 1.7l.12 1.65c.01.01.34.3.53.43.18.12.7.19 1.3.01.6-.18 1.01-.58 1.07-.64.04-.06.1-.2.11-.26l.2-.69c.06-.2.5-1.83.53-1.83.02 0 .03.02.05.06.02.04.04.2.03.83 0 .51-.25 1.34-.37 1.69a.8.8 0 00.24.02c.14-.01.34-.03.84-.36.52-.34.6-.78.64-.9.04-.12.61-2.19.64-2.2.02 0 .03.01.05.06.01.05.05.4.02 1.03-.02.5-.22 1.16-.3 1.42.13-.01.44-.06.63-.17.24-.13.7-.61.89-1.07.19-.45.44-1.77.5-1.77s.03.03.07.45c.04.33-.08 1.08-.14 1.4.08 0 .3-.07.63-.32.4-.3 1.58-2.23 1.4-4.18a6.3 6.3 0 00-1.7-3.79c-.55-.56-.75-.44-.85-.45-.1-.01-.69-.04-1.98.14a7.9 7.9 0 00-3.21 1.2c-.75.49-1.37 1.23-1.54 1.35-.07.06-.16.12-.46.06z" />
      <path d="M6.87 15.97c-.05-.15.08-.41.2-.7.09-.22.16-.67.18-.87a5.8 5.8 0 00.85.72c.18.11.9.41 1.19.58.29.17.37.44.38.55.02.09.17 2.05.24 3.03l-.2-.07a47.7 47.7 0 00-1.19-.31c-.23-.06-.38-.39-.46-.67a9.82 9.82 0 00-.75-1.54c-.19-.27-.4-.58-.44-.72zM9.33 9.98L7.75 9.4c.02-.04.12-.27.27-.46.34-.47.4-.49.89-.94a10.2 10.2 0 012.26-1.34 5.96 5.96 0 012-.5c.44.01 1.45.29 2.33.6.7.27 1.33.7 1.56.89-.18 0-.7-.04-1.32.06-.77.12-1.46.2-2.33.52-.86.32-1.35.52-2.47 1.5-.3.26-.54.52-.72.5-.21-.01-.53-.13-.9-.26z" />
      <path d="M10.44 6.42a6.6 6.6 0 012.08-.65c-.04-.05-.18-.2-.37-.38a6.1 6.1 0 00-1.88-1.07 5.21 5.21 0 00-3.25-.1 4.89 4.89 0 00-2.73 2.97c-.47 1.4-.3 2.43.02 3.12.26.55 1 1.3 1.34 1.61l1.11 1.01a5.4 5.4 0 010-1.9 5.08 5.08 0 011.06-2.77c.67-.9 1.77-1.36 2.62-1.84z" />
    </>
  ),
  hockey: (
    <>
      <path d="M12 16.41c-2.42 0-5.14-.62-7.29-1.66-.79-.39-1.46-1.01-2.01-1.5a.16.16 0 00-.2-.02.2.2 0 00-.07.07.24.24 0 00-.03.11v.68c0 1.5 1.08 2.86 3.05 3.8 1.76.84 4.09 1.31 6.55 1.31s4.79-.47 6.55-1.31c1.97-.94 3.05-2.3 3.05-3.8v-.68a.24.24 0 00-.03-.1.2.2 0 00-.07-.09.16.16 0 00-.2.02c-.55.5-1.22 1.12-2.01 1.5A17.55 17.55 0 0112 16.42z" />
      <path d="M21.6 9.5c-.04-1.37-1.12-2.6-3.05-3.47C16.8 5.23 14.46 4.8 12 4.8s-4.79.44-6.55 1.23c-1.93.86-3 2.1-3.05 3.46v.88c0 .32.24.82.68 1.23.5.49 1.27.96 2.2 1.38A17.2 17.2 0 0012 14.4c2.23 0 4.74-.53 6.72-1.42.93-.42 1.7-.9 2.2-1.38.44-.4.68-.91.68-1.23V9.5z" />
    </>
  ),
  cybersport: (
    <path d="M21.08 11.56c-.84-3.92-2.12-6.01-4.01-6.58a4.2 4.2 0 00-2.76.1c-.61.18-1.3.37-2.31.37-1 0-1.7-.2-2.31-.37a4.53 4.53 0 00-2.8-.1c-1.88.57-3.16 2.66-4.02 6.58-.93 4.22-.47 6.89 1.28 7.5.24.1.49.14.74.14 1.2 0 2.16-1.02 2.82-1.85.74-.95 1.6-1.43 4.29-1.43 2.4 0 3.39.33 4.25 1.43a5.9 5.9 0 001.55 1.46c.68.4 1.35.5 2.01.26 1.03-.36 1.62-1.3 1.76-2.83.1-1.16-.06-2.7-.49-4.68zm-11-.22H8.8v1.31c0 .18-.07.34-.19.47a.63.63 0 01-.9 0 .66.66 0 01-.2-.47v-1.3H6.25a.63.63 0 01-.45-.2.66.66 0 010-.92c.12-.13.28-.2.45-.2h1.28v-1.3c0-.18.07-.34.19-.47a.63.63 0 01.9 0c.12.13.19.3.19.47v1.3h1.28c.17 0 .33.07.45.2a.66.66 0 010 .92.63.63 0 01-.45.2zm3.36.17a.79.79 0 01-.45-.14.81.81 0 01-.34-.84c.04-.16.11-.3.22-.42a.8.8 0 01.88-.18.8.8 0 01.35.3.83.83 0 01-.1 1.04.8.8 0 01-.56.24zm1.76 1.8a.79.79 0 01-.45-.14.81.81 0 01-.34-.84c.04-.16.11-.3.22-.42a.8.8 0 01.88-.18.83.83 0 01.26 1.33.8.8 0 01-.57.25zm0-3.6a.79.79 0 01-.45-.14.81.81 0 01-.34-.84c.04-.16.11-.3.22-.42a.8.8 0 01.88-.18.8.8 0 01.35.3.83.83 0 01-.1 1.04.8.8 0 01-.56.24zm1.76 1.8a.79.79 0 01-.45-.14.81.81 0 01-.34-.84c.04-.16.11-.3.22-.42a.8.8 0 01.88-.18.8.8 0 01.35.3.83.83 0 01-.1 1.04.8.8 0 01-.56.24z" />
  ),
  tennis: (
    <>
      <path d="M17.1 10l1.2-.1a8.3 8.3 0 00-8.2-8.2 7.2 7.2 0 007 8.3zM9.9 18.3a7.3 7.3 0 00.1-1.2 7.2 7.2 0 00-8.3-7 8.3 8.3 0 008.2 8.2z" />
      <path d="M11.3 8.7a8.4 8.4 0 01-2.4-7 8.4 8.4 0 00-7.2 7.2 8.5 8.5 0 017 2.4 8.4 8.4 0 012.4 7 8.4 8.4 0 007.2-7.2 8.5 8.5 0 01-7-2.4z" />
    </>
  ),
  all_sports: (
    <path d="M12.62 7.2c0-1.12 0-1.68.21-2.1a2 2 0 01.88-.88C14.14 4 14.7 4 15.8 4h.99c1.12 0 1.68 0 2.1.22.38.19.69.5.88.87.22.43.22.99.22 2.11v.98c0 1.12 0 1.68-.22 2.11a2 2 0 01-.87.88c-.43.21-.99.21-2.11.21h-.98c-1.12 0-1.68 0-2.11-.21a2 2 0 01-.88-.88c-.21-.43-.21-.99-.21-2.1V7.2zM12.62 15.82c0-1.12 0-1.68.21-2.11a2 2 0 01.88-.88c.43-.21.99-.21 2.1-.21h.99c1.12 0 1.68 0 2.1.21.38.2.69.5.88.88.22.43.22.99.22 2.1v.99c0 1.12 0 1.68-.22 2.1a2 2 0 01-.87.88c-.43.22-.99.22-2.11.22h-.98c-1.12 0-1.68 0-2.11-.22a2 2 0 01-.88-.87c-.21-.43-.21-.99-.21-2.11v-.98zM4 7.2c0-1.12 0-1.68.22-2.1a2 2 0 01.87-.88C5.52 4 6.08 4 7.2 4h.98c1.12 0 1.68 0 2.11.22.38.19.68.5.88.87.21.43.21.99.21 2.11v.98c0 1.12 0 1.68-.21 2.11a2 2 0 01-.88.88c-.43.21-.99.21-2.1.21H7.2c-1.12 0-1.68 0-2.1-.21a2 2 0 01-.88-.88C4 9.86 4 9.3 4 8.2V7.2zM4 15.82c0-1.12 0-1.68.22-2.11a2 2 0 01.87-.88c.43-.21.99-.21 2.11-.21h.98c1.12 0 1.68 0 2.11.21.38.2.68.5.88.88.21.43.21.99.21 2.1v.99c0 1.12 0 1.68-.21 2.1a2 2 0 01-.88.88c-.43.22-.99.22-2.1.22H7.2c-1.12 0-1.68 0-2.1-.22a2 2 0 01-.88-.87C4 18.48 4 17.92 4 16.8v-.98z" />
  ),
  heart_filled: (
    <path d="M12.82 5.58L12 6.4l-.82-.82a5.37 5.37 0 10-7.6 7.6l7.89 7.9c.3.29.77.29 1.06 0l7.9-7.9a5.38 5.38 0 10-7.61-7.6z" />
  ),
  heart_outline: (
    <path d="M12.82 5.58L12 6.4l-.82-.82a5.37 5.37 0 10-7.6 7.6l7.89 7.9c.3.29.77.29 1.06 0l7.9-7.9a5.38 5.38 0 10-7.61-7.6zm6.55 6.54L12 19.48l-7.36-7.36a3.88 3.88 0 015.48-5.48L11.47 8c.3.3.79.29 1.08-.02l1.33-1.34a3.88 3.88 0 015.49 5.48z" />
  ),
  official: (
    <>
      <path
        d="M21.57 11.03c.26.4.43.71.43.97s-.17.57-.43.97c-.25.4-.6.82-.73 1.39-.15.57-.09 1.1-.06 1.59.03.45 0 .82-.12 1.05-.1.23-.42.43-.85.63-.42.22-.9.42-1.33.85-.43.42-.63.9-.86 1.33-.22.43-.42.71-.62.85-.23.12-.6.15-1.05.12-.48-.03-1.02-.09-1.56.06-.57.14-1 .48-1.4.73-.4.26-.7.43-.96.43s-.57-.17-.97-.43c-.4-.25-.82-.6-1.39-.73-.57-.15-1.1-.09-1.59-.06-.46.03-.82 0-1.05-.12-.23-.1-.43-.42-.63-.85-.22-.42-.42-.9-.82-1.33-.43-.43-.91-.63-1.34-.86A2.52 2.52 0 013.4 17a2.18 2.18 0 01-.11-1.05c.03-.46.08-1.02-.06-1.6-.14-.56-.48-.99-.74-1.38-.31-.4-.48-.71-.48-.97s.17-.57.43-.97c.25-.4.6-.82.73-1.39.15-.57.09-1.1.06-1.59-.03-.45 0-.82.12-1.05.14-.23.42-.43.85-.63.42-.22.9-.42 1.33-.85.43-.42.63-.9.83-1.33.22-.43.42-.71.62-.85.23-.12.57-.15 1.05-.12.49.03 1.03.09 1.6-.06.56-.14.99-.48 1.39-.73.42-.26.73-.43.99-.43s.57.17.97.43c.4.25.82.6 1.39.73.57.15 1.1.09 1.56.06.46-.03.82 0 1.05.12.23.1.43.42.63.85.2.42.42.9.85 1.33.43.43.9.63 1.33.86.43.22.71.42.86.62.11.23.14.57.11 1.05-.03.46-.09 1.02.06 1.6.17.53.5.99.76 1.38zm-3.4.94c.08-.45.08-1-.18-1.53a1.78 1.78 0 00-1.64-.97h-2.22c-.06 0-.11 0-.08-.06a5 5 0 00.05-2.07 3.08 3.08 0 00-.5-1.3c-.3-.4-.75-.69-1.17-.72-.43-.02-.77.12-.94.32-.17.22-.23.45-.29.65-.08.43-.08.85-.22 1.3-.4 1.4-1.4 2.3-2.42 3.59H7.31a1.6 1.6 0 00-1.56 1.59v3c0 .86.68 1.6 1.56 1.6h1.3c.04 0 .07.03.07.03.82.23 1.76.68 2.78.7H14.81c.6 0 1.11-.05 1.56-.33a2 2 0 00.89-1.4l.9-4.4z"
        fill="#305DFC"
      />
      <path
        d="M18.16 11.97c.09-.45.09-1-.17-1.53a1.78 1.78 0 00-1.64-.97h-2.22c-.06 0-.11 0-.08-.06a5 5 0 00.05-2.07 3.08 3.08 0 00-.5-1.3c-.3-.4-.75-.69-1.17-.72-.43-.02-.77.12-.94.32-.17.22-.23.45-.29.65-.08.43-.08.85-.22 1.3-.4 1.4-1.4 2.3-2.42 3.59H7.31a1.6 1.6 0 00-1.56 1.59v3c0 .86.68 1.6 1.56 1.6h1.3c.04 0 .07.03.07.03.82.23 1.76.68 2.78.7H14.81c.6 0 1.11-.05 1.56-.33a2 2 0 00.89-1.4l.9-4.4z"
        fill="#305DFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.32 7.96a.72.72 0 00-1.02 0l-6.32 6.32L7.7 12a.72.72 0 10-1 1.02l2.79 2.79c.28.28.73.28 1 0l6.84-6.83a.72.72 0 000-1.01z"
        fill="#fff"
      />
    </>
  ),
  close_circle: (
    <path d="M12 2a10 10 0 110 20 10 10 0 010-20zm0 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm3.4 4.9h.1c.3.3.3.8.1 1v.1L13 12l2.4 2.5c.3.2.3.7.1 1-.3.3-.8.3-1 .1h-.1L12 13l-2.5 2.4c-.2.3-.7.3-1 .1a.8.8 0 01-.1-1v-.1L11 12 8.5 9.5a.8.8 0 01-.1-1c.3-.3.8-.3 1-.1h.1L12 11l2.5-2.4c.2-.3.7-.3 1-.1z" />
  ),
  data: (
    <path d="M18.3 3C19.8 3 21 4.2 21 5.7v12.5c0 1.6-1.2 2.8-2.8 2.8H5.8A2.7 2.7 0 013 18.2V5.7C3 4.2 4.2 3 5.8 3h12.4zm0 1.5H5.7c-.6 0-1.2.6-1.2 1.2v12.5c0 .7.6 1.3 1.3 1.3h12.4c.7 0 1.3-.6 1.3-1.3V5.7c0-.6-.6-1.2-1.3-1.2zM7.7 9c.4 0 .7.3.8.6V16.3a.7.7 0 01-1.5 0V9.7c0-.4.3-.7.8-.7zm8.6-2c.3 0 .6.3.7.6v8.7c0 .4-.3.7-.8.7a.7.7 0 01-.7-.6V7.7c0-.4.3-.7.8-.7zM12 12c.3 0 .7.3.7.6v3.7c0 .4-.3.7-.7.7a.7.7 0 01-.7-.6v-3.7c0-.4.3-.7.7-.7z" />
  ),
  close: (
    <path d="M4.4 4.6v-.1c.3-.3.8-.3 1-.1h.1L12 11l6.5-6.4a.7.7 0 111 1L13.1 12l6.4 6.5c.3.2.3.7.1 1-.3.3-.8.3-1 .1h-.1L12 13l-6.5 6.4a.7.7 0 01-1-1l6.4-6.5-6.4-6.5a.8.8 0 01-.1-1z" />
  ),
  article: (
    <path d="M17.75 2c1.2 0 2.17.93 2.25 2.1v15.65c0 1.19-.93 2.16-2.1 2.24H6.25c-1.2 0-2.17-.92-2.24-2.09L4 19.75V4.25c0-1.19.93-2.17 2.1-2.24h11.65zm0 1.5H6.25c-.38 0-.7.28-.74.65l-.01.1v15.5c0 .38.28.7.65.74h11.6c.38 0 .7-.27.74-.64l.01-.1V4.25c0-.38-.28-.7-.65-.74h-.1zm-5.5 9.5a.75.75 0 01.1 1.49h-4.6a.75.75 0 01-.1-1.49h4.6zm4-3a.75.75 0 01.1 1.5h-8.6a.75.75 0 01-.1-1.5h8.6zm0-3a.75.75 0 01.1 1.5h-8.6a.75.75 0 01-.1-1.5h8.6z" />
  ),
  settings: (
    <path d="M12 2.3c.7 0 1.5 0 2.2.2.3 0 .5.3.6.7l.1 1.5a1.4 1.4 0 002 1l1.4-.5c.3-.1.6 0 .8.2 1 1 1.8 2.3 2.2 3.7.1.4 0 .7-.2.9l-1.3.9a1.4 1.4 0 000 2.2l1.3 1c.2.1.3.4.2.8a9.8 9.8 0 01-2.2 3.7c-.2.3-.5.3-.8.2l-1.4-.6a1.4 1.4 0 00-2 1.1l-.1 1.5c0 .4-.3.6-.6.7-1.4.3-3 .3-4.4 0a.8.8 0 01-.6-.7l-.1-1.5a1.4 1.4 0 00-2-1l-1.4.5c-.3.2-.6 0-.8-.1-1-1.1-1.8-2.4-2.2-3.8-.1-.4 0-.7.2-.9l1.3-.9a1.4 1.4 0 000-2.2l-1.3-1a.8.8 0 01-.2-.8 9.8 9.8 0 012.2-3.7c.2-.3.5-.3.8-.2l1.4.6c.4.2.9.1 1.3 0 .4-.3.6-.7.7-1.1l.1-1.5c0-.4.3-.6.6-.7.7-.2 1.5-.2 2.2-.2zM12 9a3 3 0 100 6 3 3 0 000-6z" />
  ),
  exit_filled: (
    <path d="M12 4.35415v.64582l1.7453.00002c.3792 0 .6928.28152.743.64711l.007.10165.0067 4.25124h-1.5l-.0055-3.5L12 6.49997l.0005 4.50493 1.0015-.0009.001-.004h1.501l-.001.004h4.939l-1.7224-1.72377c-.2662-.26632-.2904-.68298-.0725-.97656l.0727-.0841c.2663-.26622.6829-.29036.9765-.07245l.0841.07263 2.9966 2.99765c.266.2661.2904.6823.073.9758l-.0725.0842-2.9966 3.0034c-.2925.2932-.7674.2938-1.0606.0012-.2666-.266-.2913-.6826-.0738-.9764l.0726-.0842 1.7129-1.7174-7.4315.0009L12 17.001l1.0139.0004L13.007 13.5h1.501l.0072 4.2502c.0006.3801-.2817.6946-.6482.7444l-.1018.0068L12 18.501v.749c0 .4664-.4212.8197-.8804.7386l-8.50005-1.5013C2.26121 18.424 2 18.1126 2 17.7487V5.74999c0-.36731.26601-.68057.62847-.74009l8.50003-1.39583c.4566-.07499.8715.27735.8715.74008zM8.50215 11.5C7.94868 11.5 7.5 11.9487 7.5 12.5021c0 .5535.44868 1.0022 1.00215 1.0022.55347 0 1.00215-.4487 1.00215-1.0022 0-.5534-.44868-1.0021-1.00215-1.0021z" />
  ),
  clock: (
    <path d="M12 2c5.523 0 10 4.478 10 10s-4.477 10-10 10S2 17.522 2 12 6.477 2 12 2zm0 1.667c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333zM11.25 6c.3795 0 .6935.28233.7431.64827L12 6.75V12h3.25c.414 0 .75.336.75.75 0 .3795-.2823.6935-.6483.7431L15.25 13.5h-4c-.3795 0-.6935-.2823-.7431-.6483L10.5 12.75v-6c0-.414.336-.75.75-.75z" />
  ),
  copy_regular: (
    <path d="M5.503 4.627L5.5 6.75v10.504a3.25 3.25 0 003.25 3.25h8.616a2.251 2.251 0 01-2.122 1.5H8.75A4.75 4.75 0 014 17.254V6.75c0-.98.627-1.815 1.503-2.123zM17.75 2A2.25 2.25 0 0120 4.25v13a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-13A2.25 2.25 0 018.75 2h9zm0 1.5h-9a.75.75 0 00-.75.75v13c0 .414.336.75.75.75h9a.75.75 0 00.75-.75v-13a.75.75 0 00-.75-.75z" />
  ),
  done: (
    <>
      <path d="M12 22a10 10 0 100-20 10 10 0 000 20z" />
      <path
        d="M15.2 9l-4.5 4.5-2-2c-.2-.3-.7-.3-1 0-.3.3-.3.8 0 1.1l2.5 2.4c.3.3.8.3 1.1 0l5-5c.3-.3.3-.8 0-1.1a.9.9 0 00-1.1.1z"
        fill="#fff"
      />
    </>
  ),
  checkmark2: (
    <path d="M8.5 16.59l-3.8-3.8a1 1 0 00-1.4 1.42l4.5 4.5a1 1 0 001.4 0l11-11a1 1 0 00-1.4-1.42L8.5 16.6z" />
  ),
  add: (
    <path d="M11.75 3c.38 0 .7.28.74.65l.01.1V11h7.25a.75.75 0 01.1 1.5H12.5v7.25a.75.75 0 01-1.49.1V12.5H3.74a.75.75 0 01-.1-1.5H11V3.75c0-.41.34-.75.75-.75z" />
  ),
  comment: (
    <path d="M5.25 18C3.45507 18 2 16.5449 2 14.75V6.25C2 4.45507 3.45507 3 5.25 3H18.75C20.5449 3 22 4.45507 22 6.25V14.75C22 16.5449 20.5449 18 18.75 18H13.0125L7.99868 21.7507C7.44585 22.1642 6.6625 22.0512 6.24901 21.4984C6.08736 21.2822 6 21.0196 6 20.7499L5.99921 18H5.25ZM12.5135 16.5H18.75C19.7165 16.5 20.5 15.7165 20.5 14.75V6.25C20.5 5.2835 19.7165 4.5 18.75 4.5H5.25C4.2835 4.5 3.5 5.2835 3.5 6.25V14.75C3.5 15.7165 4.2835 16.5 5.25 16.5H7.49879L7.499 17.2498L7.49986 20.2506L12.5135 16.5Z" />
  ),
  vk: (
    <path d="M22.94 17.56c.2.7-.14 1.04-.82 1.04H19.7c-.9 0-1.17-.76-2.82-2.36-1.45-1.4-2.07-1.6-2.4-1.6-.5 0-.63.14-.63.83v2.16c0 .62-.2.97-1.72.97-2.54 0-5.36-1.53-7.36-4.45C1.83 9.92 1 6.72 1 6.1c0-.34.14-.69.83-.69h2.4c.62 0 .83.28 1.1.97 1.17 3.48 3.17 6.53 4 6.53.27 0 .4-.14.4-.9V8.32c-.06-1.67-.96-1.8-.96-2.36 0-.28.2-.56.62-.56h3.78c.55 0 .7.28.7.9v4.8c0 .48.2.7.34.7.27 0 .55-.22 1.1-.77 1.78-1.95 3.02-4.94 3.02-4.94.14-.34.42-.69 1.03-.69h2.41c.76 0 .9.42.76.9-.35 1.4-3.23 5.56-3.23 5.56-.28.42-.35.63 0 1.11.27.35 1.1 1.11 1.65 1.74 1.03 1.18 1.78 2.15 2 2.85Z" />
  ),
  facebook: (
    <path d="M16.5 5.43c0 .17-.06.22-.23.22h-1.6c-.71 0-1.14.37-1.18 1.08-.03.56 0 1.13-.03 1.69 0 .2.12.19.25.19l2.44-.01c.23 0 .31.05.29.3-.1.94-.2 1.89-.28 2.83-.01.21-.12.26-.3.26h-1.93c-.46 0-.41-.07-.41.4v8.85c0 .32-.07.4-.38.38h-3.3c-.3.01-.36-.1-.36-.37v-4.46c0-1.5 0-3.02.02-4.52 0-.26-.07-.33-.33-.33-.46.02-.93 0-1.4.01-.2.01-.27-.05-.26-.24V8.89c0-.18.05-.24.23-.24.48 0 .97-.02 1.44 0 .28.03.34-.08.33-.33V6.17c.02-.87.26-1.7.78-2.4a3.3 3.3 0 0 1 2.66-1.36c1.11-.05 2.23-.03 3.34-.04.15 0 .2.06.2.2v2.86Z" />
  ),
  twitter: (
    <path d="M22.53 5.78c-.74.33-1.53.55-2.36.65.85-.51 1.5-1.32 1.8-2.28-.79.47-1.67.81-2.6 1a4.11 4.11 0 0 0-7.02 3.75 11.69 11.69 0 0 1-8.48-4.3 4.1 4.1 0 0 0 1.27 5.5 4.1 4.1 0 0 1-1.87-.52v.05c0 2 1.42 3.66 3.3 4.04a4.12 4.12 0 0 1-1.85.07 4.12 4.12 0 0 0 3.84 2.86 8.26 8.26 0 0 1-6.1 1.7 11.65 11.65 0 0 0 6.32 1.85c7.57 0 11.7-6.27 11.7-11.7V7.9c.8-.58 1.5-1.3 2.05-2.13Z" />
  ),
  telegram: (
    <path d="M20.32 3.86S22.16 3.15 22 4.9c-.05.72-.46 3.23-.87 5.94l-1.28 8.04s-.1 1.18-.98 1.38c-.87.2-2.3-.72-2.56-.92-.2-.15-3.84-2.46-5.17-3.58-.36-.31-.77-.93.05-1.64 1.84-1.7 4.05-3.8 5.38-5.12.61-.62 1.23-2.05-1.33-.31-3.64 2.5-7.22 4.86-7.22 4.86s-.82.52-2.36.05c-1.54-.46-3.33-1.07-3.33-1.07s-1.23-.77.87-1.59c0 0 8.86-3.63 11.93-4.91 1.18-.52 5.18-2.16 5.18-2.16Z" />
  ),
  instagram: (
    <>
      <path d="M21.5 12.02c0 1.32.02 2.63 0 3.95A5 5 0 0 1 16.5 21H8.5a5 5 0 0 1-5.03-5.04l.01-7.94C3.5 5.21 5.7 3 8.51 3h7.99a5 5 0 0 1 5.02 5.04l-.01 4Zm-9 4.98c2.78 0 5-2.23 4.99-5.01A4.97 4.97 0 0 0 12.5 7c-2.77-.02-5 2.2-5 4.99 0 2.78 2.23 5 5 5Zm7-10.99c.02-.52-.42-1-.95-1.02a1.02 1.02 0 0 0-1.04.99c-.01.52.43 1 .96 1.02.54.02 1.03-.44 1.04-.99Z" />
      <path d="M12.5 15.01a3.01 3.01 0 1 1 0-6.02 3.01 3.01 0 0 1 0 6.02Z" />
    </>
  ),
  website: (
    <path d="M21.09 8.4a9.33 9.33 0 0 0-6.44-5.45c.79 1.28 1.31 3.23 1.6 5.44h4.84ZM10.35 2.95a9.33 9.33 0 0 0-6.43 5.44h4.83c.29-2.21.81-4.16 1.6-5.44ZM14.97 8.4c-.5-3.56-1.63-5.71-2.47-5.71-.84 0-1.98 2.15-2.47 5.7h4.94ZM3.92 15.6a9.33 9.33 0 0 0 6.43 5.45c-.79-1.28-1.31-3.23-1.6-5.44H3.92ZM14.65 21.05a9.33 9.33 0 0 0 6.44-5.44h-4.84c-.29 2.21-.81 4.16-1.6 5.44ZM10.03 15.6c.5 3.56 1.63 5.71 2.47 5.71.84 0 1.98-2.15 2.47-5.7h-4.94ZM8.51 12c0-.79.03-1.57.09-2.33H3.5a8.65 8.65 0 0 0 0 4.66h5.13A30.4 30.4 0 0 1 8.51 12ZM15.2 12c0-.81-.02-1.6-.08-2.33H9.88a29.44 29.44 0 0 0 0 4.66h5.21c.09-.73.12-1.52.12-2.33ZM21.5 9.67h-5.13a30.55 30.55 0 0 1 0 4.66h5.12a8.65 8.65 0 0 0 0-4.66Z" />
  ),
  youtube: (
    <path d="M21.58 7.17c-.24-.84-.9-1.5-1.75-1.75C18.27 5 12 5 12 5s-6.27 0-7.83.42c-.85.24-1.51.9-1.75 1.75C2 8.74 2 12 2 12s0 3.26.42 4.83c.24.84.9 1.5 1.75 1.75C5.73 19 12 19 12 19s6.27 0 7.83-.42c.85-.24 1.51-.9 1.75-1.75C22 15.26 22 12 22 12s0-3.26-.42-4.83Zm-11.57 7.85V8.98L15.2 12l-5.18 3.02Z" />
  ),
  chat: (
    <path d="M12 2a10 10 0 1 1-4.59 18.89L3.6 21.96a1.25 1.25 0 0 1-1.54-1.54l1.06-3.83A10 10 0 0 1 12 2Zm0 1.5a8.5 8.5 0 0 0-7.43 12.64l.15.27-1.1 3.98 3.98-1.11.27.15A8.5 8.5 0 1 0 12 3.5ZM8.75 13h4.5a.75.75 0 0 1 .1 1.5h-4.6a.75.75 0 0 1-.1-1.5h4.6-4.5Zm0-3.5h6.5a.75.75 0 0 1 .1 1.5h-6.6a.75.75 0 0 1-.1-1.5h6.6-6.5Z" />
  ),
  checkmark_circle: (
    <path d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm-1.25 9.94 4.47-4.47a.75.75 0 0 1 1.13.98l-.07.08-5 5a.75.75 0 0 1-.98.07l-.08-.07-2.5-2.5a.75.75 0 0 1 .98-1.13l.08.07 1.97 1.97 4.47-4.47-4.47 4.47Z" />
  ),
  block: (
    <path d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm-4.25 7.75h8.5a.75.75 0 0 1 .1 1.5h-8.6a.75.75 0 0 1-.1-1.5h8.6-8.5Z" />
  ),
  info_outline: (
    <path d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20Zm0 1.5a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17Zm0 7c.38 0 .69.28.74.65v.1l.01 5.5a.75.75 0 0 1-1.5.1v-5.6c0-.41.33-.75.75-.75ZM12 7a1 1 0 1 1 0 2 1 1 0 0 1 0-2Z" />
  ),
  more_vertical: (
    <path d="M10.4 12a1.6 1.6 0 1 1 3.2 0 1.6 1.6 0 0 1-3.2 0Zm0-6a1.6 1.6 0 1 1 3.2 0 1.6 1.6 0 0 1-3.2 0Zm0 12a1.6 1.6 0 1 1 3.2 0 1.6 1.6 0 0 1-3.2 0Z" />
  ),
} as const

export type TSvgName = keyof typeof svgPath

type TIconCommonProps = {
  reverse?: boolean
  name: TSvgName
} & React.SVGAttributes<SVGSVGElement>

export const Icon: FC<TIconCommonProps> = (props) => {
  const { width, reverse, name, style, children, ...rest } = props
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: reverse ? `rotate(${180}deg)` : undefined, ...style }}
      {...rest}
    >
      {children}
      {svgPath[name]}
    </svg>
  )
}
