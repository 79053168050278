import Typography, { TypographyProps } from "@material-ui/core/Typography"
import React, { FC } from "react"

export const H5 = (props: TypographyProps) => <Typography variant="h5" gutterBottom {...props} />
export const Text1: FC<TypographyProps> = (props) => <Typography variant="body1" {...props} />
export const Caption1: FC<TypographyProps> = (props) => (
  <Typography variant="subtitle1" component={"p" as any} {...props} />
)
export const H1: FC<TypographyProps & { component?: any }> = (props) => (
  <Typography variant="h1" {...props} />
)
export const H2: FC<TypographyProps & { component?: any }> = (props) => (
  <Typography variant="h2" {...props} />
)
export const H3: FC<TypographyProps & { component?: any }> = (props) => (
  <Typography variant="h3" {...props} />
)
export const H4: FC<TypographyProps & { component?: any }> = (props) => (
  <Typography variant="h4" {...props} />
)

