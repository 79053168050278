import NoSsr from "@material-ui/core/NoSsr"
import clsx from "clsx"
import React, { FC, useRef } from "react"
import { Helmet } from "react-helmet"
import { Outlet, useLoaderData, useLocation, useMatches } from "react-router"
import { useDi } from "../App/di"
import { CountryTip } from "../country/country-tip"
import { Footer } from "../footer/footer-root"
import { Header } from "../header/header-root"
import {
  TArticlePage_res,
  TBonusListPage_res,
  TGlossary_res,
  TLayout_res,
  TPageArticleBonus_res,
  TPageArticleTip_res,
  TRatingsPage_res,
  TRouteResponse,
  TWebRegionListItem,
} from "../payload"
import { RATING_ALL } from "../rating/rating-config"
import { ERouteId, ERouteUrl } from "../routes/routes-config"
import { TWidgetConfig } from "../store/store"
import { excludeRegion } from "../util/excludeRegion"
import { trimEndPathnameSlash } from "../util/url"
import { MainWidget } from "../widget/main-widget"
import { WidgetPopup } from "../widget/widget-popup"
import { LayoutLazyComponents } from "./nossr"

export type TLayoutCs =
  | "wrapper"
  | "main"
  | "pageWrapper"
  | "inner"
  | "grey"
  | "headerOffset"
export type THrefLang = {
  lang: string
  href: string
  domain: string
}

export const Layout: FC = () => {
  let layout = (useLoaderData() as TLayout_res).data
  const location = useLocation()
  const {
    region,
    region_list,
    store,
    cs: { Layout: c },
  } = useDi()

  const matches = useMatches()
  // console.log("matches", matches)
  let path = trimEndPathnameSlash(location.pathname)
  let map_region_to_hreflang = (r: TWebRegionListItem): THrefLang => ({
    href: "https://" + r.domain + path,
    lang: r.hreflang,
    domain: r.domain,
  })
  const getHreflangs = (
    regions: {
      hreflang: string
      domain: string
    }[]
  ) => {
    hreflangs = regions.map((r) => {
      let href = "https://" + r.domain + path
      return { lang: r.hreflang, href, domain: r.domain }
    })
  }

  let regions = region_list.filter((r) => !r.is_hidden)
  let hreflangs: THrefLang[] = []

  for (let match_key in matches) {
    let match = matches[match_key]
    let data = match.data
    if (data === undefined) {
      continue
    }
    if (match.id === ERouteId.home || match.id === ERouteId.blog_list) {
      hreflangs = regions.map(map_region_to_hreflang)
    } else if (
      match.id === ERouteId.academy ||
      match.id === ERouteId.glossary_list ||
      match.id === ERouteId.news_list ||
      match.id === ERouteId.team ||
      match.id === ERouteId.team_squad ||
      match.id === ERouteId.tip_list ||
      match.id === ERouteId.tip_list_sport ||
      match.id === ERouteId.tip_list_tournament
    ) {
      hreflangs = excludeRegion(regions, "mx").map(map_region_to_hreflang)
    } else if (match.id === ERouteId.blog || match.id === ERouteId.news) {
      let page = match.data as TArticlePage_res
      getHreflangs(page.data.article.regions)
    } else if (match.id === ERouteId.bonus || match.id === ERouteId.tip) {
      let page = match.data as TPageArticleBonus_res | TPageArticleTip_res
      getHreflangs(page.data.regions)
    } else if (match.id === ERouteId.glossary) {
      let page = match.data as TGlossary_res
      getHreflangs(page.data.glossary.regions)
    } else if (match.id === ERouteId.bonus_list) {
      let page = match.data as TBonusListPage_res
      let is_not_bk = !page.data.bk.value // TODO check if it's by empty string or undefined
      if (is_not_bk) {
        hreflangs = regions.map((r) => {
          let is_mx = r.domain === "betnbet.mx"
          let href =
            "https://" +
            r.domain +
            (is_mx ? ERouteUrl.bonuses_mx : ERouteUrl.bonuses)
          return { lang: r.hreflang, href, domain: r.domain }
        })
      }
    } else if (match.id === ERouteId.rating_list) {
      let page = match.data as TRatingsPage_res
      let is_rating_all = page.data.rating.slug === RATING_ALL
      if (is_rating_all) {
        hreflangs = regions.map((r) => {
          let is_mx = r.domain === "betnbet.mx"
          let href =
            "https://" + r.domain + (is_mx ? ERouteUrl.bk_mx : ERouteUrl.bk)
          return { lang: r.hreflang, href, domain: r.domain }
        })
      }
    }
  }

  const isMounted = useRef(false)
  React.useEffect(() => {
    if (isMounted.current) {
      let data_with_widget = Object.values(matches).find((ui_match) => {
        let loader_data = ui_match.data as TRouteResponse | undefined
        return loader_data?.widget != undefined
      })?.data as TRouteResponse | undefined
      let widget: TWidgetConfig | undefined = data_with_widget?.widget
      store.widget.setWidgetConfig(widget)
    } else {
      isMounted.current = true
    }
  }, [location])

  const path_to_bonuses =
    region.name === "mx" ? ERouteUrl.bonuses_mx : ERouteUrl.bonuses

  const is_grey_bg =
    location.pathname === "/" ||
    location.pathname === ERouteUrl.blog ||
    location.pathname === ERouteUrl.news ||
    location.pathname.startsWith(path_to_bonuses) ||
    location.pathname.startsWith(ERouteUrl.tips) ||
    location.pathname.startsWith(ERouteUrl.user)

  return (
    <>
      <Helmet titleTemplate="%s" defaultTitle={layout.seo_title}>
        <meta name="description" content={layout.seo_description} />
        <meta property="og:image" content="/img/home-hero.jpg" />
      </Helmet>
      <Helmet>
        {hreflangs.map((h) => (
          <link
            key={h.href}
            rel="alternate"
            href={h.href}
            hrefLang={h.lang}
            // {...({ hreflang: .hreflang } as any)} // transform "hrefLang" to lowercase
          />
        ))}
      </Helmet>
      <div className={clsx(c.wrapper, is_grey_bg && c.grey)}>
        <CountryTip />
        <Header layout={layout} />
        <div className={c.headerOffset} id="catfish-trigger" />
        {layout.main_widget && <MainWidget widget={layout.main_widget} />}
        <WidgetPopup />
        <main className={c.main}>
          <div className={c.pageWrapper}>
            <div className={c.inner}>
              <Outlet />
            </div>
            <Footer layout={layout} hreflangs={hreflangs} />
          </div>
        </main>
      </div>
      <NoSsr>
        <LayoutLazyComponents />
      </NoSsr>
    </>
  )
}
// {story.state.statusCode === 404 ? (
//   <NotFound />
// ) : (
//   <DataRoutes routes={props.route.children!} story={story} />
// )}
