import React, { FC } from "react"
import { useDi } from "../App/di"
import { Typography } from "../common/typography"
import { html_flags, RegionNames } from "../const/regions"
import { TWebRegionListItem } from "../payload"

type TVersionData = {
  inCountry: string
  national: string
}
const VersionData: Record<RegionNames, TVersionData> = {
  [RegionNames.BY]: {
    inCountry: " Беларуси",
    national: " белорусскую",
  },
  [RegionNames.RU]: {
    inCountry: " России",
    national: " российскую",
  },
  [RegionNames.UA]: {
    inCountry: " Украине",
    national: " украинскую",
  },
  [RegionNames.KZ]: {
    inCountry: " Казахстане",
    national: " казахстанскую",
  },
  [RegionNames.TJ]: {
    inCountry: " Таджикистане",
    national: " таджикистанскую",
  },
  [RegionNames.UZ]: {
    inCountry: " Узбекистане",
    national: " узбекистанскую",
  },
  [RegionNames.MX]: {
    inCountry: " México",
    national: " mexicana",
  },
}

export type TCountryTipCs = "link" | "text" | "flag" | "go"

export const CountryTip: FC = () => {
  const {
    cs: { CountryTip: c },
  } = useDi()

  const version = useCountryVersion()
  if (!version) return null

  const declination = VersionData[version.name as RegionNames]
  if (!declination) return null

  let flag = html_flags[version.name] || "";
  return (
    <a href={`https://${version.domain}`} className={c.link}>
      <span className={c.flag}>{flag}</span>
      {version.name === "mx" ? (
        <Typography name="h5" className={c.text}>
          Parece que estás en{declination.inCountry}.{" "}
          <b className={c.go}>Cambia</b> a la versión
          {declination.national} del sitio
        </Typography>
      ) : (
        <Typography name="h5" className={c.text}>
          Кажется, вы находитесь в{declination.inCountry}.{" "}
          <b className={c.go}>Перейти</b> на{declination.national} версию сайта
        </Typography>
      )}
    </a>
  )
}

export function useCountryVersion(): TWebRegionListItem | null {
  const { region, region_list, user_country_iso } = useDi()

  const current_region_index = region_list.findIndex(
    (r) => r.country_iso === user_country_iso
  )
  if (current_region_index < 0 || user_country_iso === region.country_iso)
    return null

  const need_region = region_list[current_region_index]

  return need_region
}
