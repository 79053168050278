import Button from "@material-ui/core/Button"
import React, { useState } from "react"

const containerStyle: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
}

class BundleError extends React.Component {
  onLine = true

  componentWillMount() {
    this.onLine = navigator.onLine
  }

  fullReload = () => {
    location.reload()
  }

  render() {
    return (
      <div style={containerStyle}>
        <div style={{ paddingBottom: "16px" }}>
          {this.onLine ? <div>Error</div> : <div>No connection</div>}
        </div>
        <Button variant="contained" color="primary" onClick={this.fullReload}>
          Reload
        </Button>
      </div>
    )
  }
}

export type TAsyncComponent = React.ComponentType & {
  load: () => Promise<React.ComponentType>
}

export const isAsyncComponent = (
  component: React.ComponentType | TAsyncComponent
): component is TAsyncComponent => {
  return Boolean((component as TAsyncComponent).load)
}

type TComponentLoaderResponse = { default: React.ComponentType<any> }

export const asyncComponent = (
  componentLoader: () => Promise<TComponentLoaderResponse>
): TAsyncComponent => {
  let Component: React.ComponentType | null = null
  let isError = false
  let loaded = false
  let loading = false

  const load = async (): Promise<void> => {
    if (Component) {
      return
    }
    if (loading) {
      return
    }
    try {
      loading = true
      const loaderResponse = await componentLoader()
      const _Component = loaderResponse.default
      Component = _Component
      isError = false
      loaded = true
    } catch (e) {
      console.error(e)
      isError = true
      loaded = false
    } finally {
      loading = false
    }
  }

  function AsyncComponent(props: any) {
    const [, rerender] = useState([])
    React.useEffect(() => {
      const loadComponent = async () => {
        await load()
        rerender([])
      }
      loadComponent()
    }, [])

    if (!loaded) return null
    if (Component) return <Component {...props} />
    if (isError) return <BundleError />

    return null
  }

  AsyncComponent.load = async (): Promise<React.ComponentType> => {
    await load()
    return Component || BundleError
  }

  return AsyncComponent
}
