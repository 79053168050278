import React from "react"
import { RouteObject } from "react-router-dom"
import { TApi } from "../graphql/graphql-sdk"
import { Layout } from "../layout/layout"
import { NotFound, NotFoundError } from "../not-found/not-found-page"
import { EBookmakerBrandType } from "../payload"
import { ERouteId, ERouteRelativeUrl, ERouteUrl } from "./routes-config"

const ServiceArticlePage = async () =>
  import(
    /* webpackChunkName: "ServicePage" */
    /* webpackMode: "lazy" */
    "../static-pages/service-article-page"
  )
const ArticlesList = async () =>
  import(
    /* webpackChunkName: "ArticlesListPage" */
    /* webpackMode: "lazy" */
    "../article/article-list-page"
  )
const BonusList = async () =>
  import(
    /* webpackChunkName: "BonusList" */
    /* webpackMode: "lazy" */
    "../bonus/bonus-list-page"
  )

// import { Outlet } from "react-router-dom"
// export const Layout: FC = () => <div>
//   <div>Layout</div>
//   <Outlet />
// </div>
// export const One: FC = () => <h1>One</h1>
// export const Two: FC = () => <h1>Two</h1>

export const get_routes: (api: TApi, region: string) => RouteObject[] = (
  api,
  region
) => {
  const isRegionMX = region === "mx"

  const paths = {
    to_bk: isRegionMX ? ERouteUrl.bk_mx : ERouteUrl.bk,
    to_bonuses: isRegionMX ? ERouteUrl.bonuses_mx : ERouteUrl.bonuses,
    to_bonus: isRegionMX ? ERouteUrl.bonus_mx : ERouteUrl.bonus,
  }

  return [
    {
      path: "/",
      element: <Layout />,
      errorElement: <NotFoundError />,
      loader: (l) => api.layout(l.request.signal),
      // loader: async ({ request }) => json(await api.layout(request.signal)),
      // loader: async ({ request }) => Response.json(await api.layout(request.signal)),
      // loader: async ({ request }) => {
      //   let r = await api.layout(request.signal)
      //   console.log(r)
      //   return r
      // },
      // children: [{path: "/", element: <One />}, {path: "/two", element: <Two />}]
      children: [
        {
          path: "",
          id: ERouteId.home,
          // element: <One />,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "HomePage" */
              /* webpackMode: "lazy" */
              "../home/home-page"
            )
            return { Component: Component.default }
          },
          loader: (l) => api.home(l.request.signal),
        },
        {
          path: ERouteUrl.contact,
          lazy: async () => ({
            Component: (await ServiceArticlePage()).default,
          }),
          loader: (l) => api.contacts(l.request.signal),
        },
        {
          path: ERouteUrl.contact_mx,
          lazy: async () => ({
            Component: (await ServiceArticlePage()).default,
          }),
          loader: (l) => api.contactsMx(l.request.signal),
        },
        {
          path: ERouteUrl.terms,
          lazy: async () => ({
            Component: (await ServiceArticlePage()).default,
          }),
          loader: (l) => api.terms(l.request.signal),
        },
        {
          path: ERouteUrl.privacy,
          lazy: async () => ({
            Component: (await ServiceArticlePage()).default,
          }),
          loader: (l) => api.privacy(l.request.signal),
        },
        {
          path: paths.to_bk + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "BookmakerPage" */
              /* webpackMode: "lazy" */
              "../bookmaker/bookmaker-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.bookmakerPage(l.request.signal, {
              slug: l.params.slug!,
              type: EBookmakerBrandType.bk,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          id: ERouteId.rating_list,
          path: paths.to_bk,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "RatingsPage" */
              /* webpackMode: "lazy" */
              "../rating/ratings-page"
            )
            return { Component: Component.default }
          },
          loader: (l) =>
            api.ratingPageBkAll(l.request.signal, {
              type: EBookmakerBrandType.bk,
            }),
        },
        {
          path: ERouteUrl.casino + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "CasinoPage" */
              /* webpackMode: "lazy" */
              "../casino/casino-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.casinoPage(l.request.signal, {
              slug: l.params.slug!,
              type: EBookmakerBrandType.casino,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          path: ERouteUrl.casino,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "RatingsCasinoAll" */
              /* webpackMode: "lazy" */
              "../rating/casino-ratings-page"
            )
            return { Component: Component.default }
          },
          loader: (l) =>
            api.ratingPageCasinoAll(l.request.signal, {
              type: EBookmakerBrandType.casino,
            }),
        },
        {
          path: ERouteUrl.review + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "ReviewPage" */
              /* webpackMode: "lazy" */
              "../review/review-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.bkReviewPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          path: ERouteUrl.review,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "ReviewAllBkPage" */
              /* webpackMode: "lazy" */
              "../review/review-all-bk-page"
            )
            return { Component: Component.default }
          },
          loader: (l) => api.allReviewsPage(l.request.signal),
        },
        {
          path: ERouteUrl.rating + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "RatingsPage" */
              /* webpackMode: "lazy" */
              "../rating/ratings-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.ratingPageBk(l.request.signal, {
              slug: l.params.slug!,
              type: EBookmakerBrandType.bk,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          path: ERouteUrl.pps + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "LocalBusinessPage" */
              /* webpackMode: "lazy" */
              "../local-business/local-business-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.localBusinessPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          path: ERouteUrl.pps,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "LocalBusinessesPage" */
              /* webpackMode: "lazy" */
              "../local-business/local-business-list-page"
            )
            return { Component: Component.default }
          },
          loader: (l) => api.localBusinessListPage(l.request.signal),
        },
        // {
        //   path: ERouteUrl.quiz + "/:slug",
        //   lazy: async () => {
        //     let Component = await import(
        //       /* webpackChunkName: "QuizPage" */
        //       /* webpackMode: "lazy" */
        //       "../quiz/quiz-page"
        //     );
        //     return { Component: Component.default }
        //   },
        //   errorElement: <NotFoundError />,
        //   loader: async (l) => {
        //     let page = await api.quizPage(l.request.signal, { slug: l.params.slug! })
        //     if (page.statusCode == 404) {
        //       throw new Response("Not Found", { status: 404 });
        //     }
        //     return page
        //   },
        // },
        {
          id: ERouteId.academy,
          path: ERouteUrl.academy,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "AcademyPage" */
              /* webpackMode: "lazy" */
              "../academy/academy-page"
            )
            return { Component: Component.default }
          },
          loader: (l) => api.academy(l.request.signal),
        },
        {
          id: ERouteId.team,
          path: ERouteUrl.team + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "TeamPage" */
              /* webpackMode: "lazy" */
              "../team/team-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.teamPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          id: ERouteId.team_squad,
          path: ERouteUrl.team + "/:slug" + ERouteUrl.squad,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "TeamSquadPage" */
              /* webpackMode: "lazy" */
              "../team/team-squad-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.teamSquadPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          id: ERouteId.glossary,
          path: ERouteUrl.glossary + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "GlossaryPage" */
              /* webpackMode: "lazy" */
              "../glossary/glossary-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.glossary(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          id: ERouteId.glossary_list,
          path: ERouteUrl.glossary,
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "GlossaryListPage" */
              /* webpackMode: "lazy" */
              "../glossary/glossary-list-page"
            )
            return { Component: Component.default }
          },
          loader: (l) => api.glossaryList(l.request.signal),
        },
        {
          id: ERouteId.news,
          path: ERouteUrl.news + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "NewsPage" */
              /* webpackMode: "lazy" */
              "../news/news-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.newsPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          id: ERouteId.blog,
          path: ERouteUrl.blog + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "BlogPage" */
              /* webpackMode: "lazy" */
              "../blog/blog-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.articleBlogPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          id: ERouteId.bonus,
          path: paths.to_bonus + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "BonusPage" */
              /* webpackMode: "lazy" */
              "../bonus/bonus-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.articleBonusPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        ...[
          { path: ERouteUrl.tips, id: ERouteId.tip_list },
          {
            path: ERouteUrl.tips + "/:sport_slug",
            id: ERouteId.tip_list_sport,
          },
          {
            path: ERouteUrl.tips + "/:sport_slug/:tournament_slug",
            id: ERouteId.tip_list_tournament,
          },
        ].map(
          (t) =>
            ({
              id: t.id,
              path: t.path,
              lazy: async () => {
                let Component = await import(
                  /* webpackChunkName: "TipsListPage" */
                  /* webpackMode: "lazy" */
                  "../tip/tip-list-page"
                )
                return { Component: Component.default }
              },
              errorElement: <NotFoundError />,
              loader: async (l) => {
                let page = await api.tipFilterListPage(l.request.signal, {
                  sport_slug: l.params.sport_slug,
                  tournament_slug: l.params.tournament_slug,
                })
                if (page.statusCode == 404) {
                  throw new Response("Not Found", { status: 404 })
                }
                return page
              },
            } as RouteObject)
        ),
        {
          id: ERouteId.tip,
          path: ERouteUrl.tip + "/:slug",
          lazy: async () => {
            let Component = await import(
              /* webpackChunkName: "TipPage" */
              /* webpackMode: "lazy" */
              "../tip/tip-page"
            )
            return { Component: Component.default }
          },
          errorElement: <NotFoundError />,
          loader: async (l) => {
            let page = await api.articleTipPage(l.request.signal, {
              slug: l.params.slug!,
            })
            if (!page || page.statusCode == 404) {
              throw new Response("Not Found", { status: 404 })
            }
            return page
          },
        },
        {
          id: ERouteId.news_list,
          path: ERouteUrl.news,
          lazy: async () => ({ Component: (await ArticlesList()).default }),
          loader: (l) => api.newsList(l.request.signal, {} as any),
        },
        {
          id: ERouteId.blog_list,
          path: ERouteUrl.blog,
          lazy: async () => ({ Component: (await ArticlesList()).default }),
          loader: (l) => api.blogList(l.request.signal, {} as any),
        },
        {
          id: ERouteId.bonus_list,
          path: paths.to_bonuses,
          lazy: async () => ({ Component: (await BonusList()).default }),
          loader: (l) => api.bonusListPage(l.request.signal, {}),
        },
        {
          path: paths.to_bonuses + "/:bk_or_category_slug",
          lazy: async () => ({ Component: (await BonusList()).default }),
          loader: (l) =>
            api.bonusListPage(l.request.signal, {
              bk_or_category_slug: l.params.bk_or_category_slug!,
            }),
        },
        user_routes(api),
        {
          path: "*",
          element: <NotFound />,
          // id: "NotFound",
          errorElement: <NotFoundError />,
          loader: () => {
            throw new Response("Not Found", { status: 404 })
          },
        },
      ],
    },
  ]
}

const user_routes: (api: TApi) => RouteObject = (api) => {
  return {
    path: ERouteUrl.user + "/:id",
    lazy: async () => {
      let Component = await import(
        /* webpackChunkName: "UserLayout" */
        /* webpackMode: "lazy" */
        "../user/user-layout"
      )
      return { Component: Component.default }
    },
    errorElement: <NotFoundError />,
    loader: async (l) => {
      let page = await api.userLayout(l.request.signal, { id: l.params.id! })
      if (page.statusCode == 404) {
        throw new Response("Not Found", { status: 404 })
      }
      return page
    },
    children: [
      {
        path: "",
        lazy: async () => {
          let Component = await import(
            /* webpackChunkName: "UserArticleList" */
            /* webpackMode: "lazy" */
            "../user/user-article-list"
          )
          return { Component: Component.default }
        },
        loader: (l) =>
          api.userBlogsPage(l.request.signal, { id: l.params.id! }),
      },
      {
        path: ERouteRelativeUrl.news,
        lazy: async () => {
          let Component = await import(
            /* webpackChunkName: "UserArticleList" */
            /* webpackMode: "lazy" */
            "../user/user-article-list"
          )
          return { Component: Component.default }
        },
        loader: (l) => api.userNewsPage(l.request.signal, { id: l.params.id! }),
      },
      {
        path: ERouteRelativeUrl.tips,
        lazy: async () => {
          let Component = await import(
            /* webpackChunkName: "UserTips" */
            /* webpackMode: "lazy" */
            "../user/user-tips"
          )
          return { Component: Component.default }
        },
        loader: (l) => api.userTipsPage(l.request.signal, { id: l.params.id! }),
      },
      {
        path: ERouteRelativeUrl.comments,
        lazy: async () => {
          let Component = await import(
            /* webpackChunkName: "UserComments" */
            /* webpackMode: "lazy" */
            "../user/user-comments"
          )
          return { Component: Component.default }
        },
        loader: (l) =>
          api.userCommentsPage(l.request.signal, { id: l.params.id! }),
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  }
}

// NOT USED
// const BetForm = asyncComponent(() =>
//   import(
//     /* webpackChunkName: "BetForm" */
//     /* webpackMode: "lazy" */
//     "../tips/TipForm"
//   )
// )
// const LayoutLanding = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "LayoutLanding" */
//       /* webpackMode: "lazy" */
//       "../layout/layout-landing"
//     )
// )
// const Search = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "Search" */
//       /* webpackMode: "lazy" */
//       "../search/search-page"
//     )
// )
// const AccountLayout = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "AccountLayout" */
//       /* webpackMode: "lazy" */
//       "../account/account-layout"
//     )
// )
// const AccountPersonal = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "AccountPersonal" */
//       /* webpackMode: "lazy" */
//       "../account/account-personal"
//     )
// )
// const AccountNotifications = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "AccountNotifications" */
//       /* webpackMode: "lazy" */
//       "../account/account-notifications"
//     )
// )
// const AccountBookmakers = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "AccountBookmakers" */
//       /* webpackMode: "lazy" */
//       "../account-page/account-bookmakers"
//     )
// )
// {
//   path: ERouteUrl.account + "/*",
//   caseSensitive: true,
//   element: <RouteWrapper />,
//   component: AccountLayout,
//   dataKey: "accountLayout",
//   children: [
//     {
//       path: "/",
//       caseSensitive: true,
//       element: <RouteWrapper />,
//       component: AccountPersonal,
//     },
//     {
//       path: "notifications", // ERouteUrl.notifications
//       caseSensitive: true,
//       element: <RouteWrapper />,
//       component: AccountNotifications,
//     },
//     // {
//     //   path: "bookmakers", // ERouteUrl.bookmakers,
//     //   caseSensitive: true,
//     //   element: <RouteWrapper />,
//     //   component: AccountBookmakers,
//     //   loadData: getLoadDataAccountBookmakers,
//     //   dataKey: "account-bookmaker",
//     // },
//   ],
// },
// const TournamentPage = asyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: "TournamentPage" */
//       /* webpackMode: "lazy" */
//       "../tournament/tournament-page"
//     )
// )
// {
//   path: ERouteUrl.tournament + "/:slug",
//   caseSensitive: true,
//   element: <RouteWrapper />,
//   component: TournamentPage,
//   dataKey: "tournament",
//   loadData: getTournamentPageLoader,
// },

// import { LoginRoot } from "../auth/login-root"
// import { SignupRoot } from "../auth/signup-root"
// {
//   path: ERouteUrl.signup,
//   caseSensitive: true,
//   element: <RouteWrapper />,
//   component: SignupRoot,
// },
// {
//   path: ERouteUrl.login,
//   caseSensitive: true,
//   element: <RouteWrapper />,
//   component: LoginRoot,
// },
