import { useMediaQuery } from "@material-ui/core"
import clsx from "clsx"
import React, { FC, memo, useState } from "react"
import { useDi } from "../App/di"
import { Img } from "../common/img"
import { usePixel } from "../hooks/use-reload-img"
import { TWidgetData } from "../payload"
import { WidgetInfo } from "./widget-info"
import { WidgetMark } from "./widget-mark"

export type TMainWidgetCs =
  | "container"
  | "root"
  | "desk"
  | "mob"
  | "feed"
  | "feedVideo"
  | "closed"
  | "mark"
  | "more"
  | "more_icon"
  | "actions"
  | "down_side"
  | "widget_info"
  | "actions_item"
  | "hide_widget"
  | "copy_link"

export const MainWidget: FC<{ widget: TWidgetData }> = memo(({ widget }) => {
  const { store } = useDi()
  const { theme } = useDi()
  const isDesctop = useMediaQuery(theme.breakpoints.up("sm")) || false

  const { main } = store.widget
  if (main.type === "hide" || !widget.bk_slug) return null

  if (
    main.type === "show if its you or no bk" &&
    main.bkSlug &&
    main.bkSlug !== widget.bk_slug
  ) {
    return null
  }

  const href = isDesctop ? widget.link : widget.mob_link || widget.link

  return <Widget widget={widget} href={href} />
})

const Widget: FC<{ widget: TWidgetData; href: string | null }> = ({
  widget,
  href,
}) => {
  const {
    cs: { MainWidget: c },
  } = useDi()

  const [closed, setClosed] = useState<boolean>(false)
  const close = () => setClosed(!closed)

  usePixel(widget.pixel, widget.pixel_mob)

  return (
    <div className={closed ? c.closed : c.container}>
      <a
        className={clsx(c.container, c.root)}
        href={href || ""}
        // target="_blank"
        itemScope
      >
        <Img
          className={c.desk}
          src={widget.image_url || ""}
          alt={widget.bk_slug || ""}
          width={1230}
          loading="eager"
        />

        <Img
          className={c.mob}
          src={widget.mob_image_url || ""}
          alt={widget.bk_slug || ""}
          width={360}
          loading="eager"
        />
        {widget.mark && <WidgetMark mark={widget.mark} className={c.mark} />}
      </a>
      <WidgetInfo
        info={widget.info}
        link={href}
        hide={close}
        className={{
          more: c.more,
          more_icon: c.more_icon,
          actions: clsx(c.actions, c.down_side),
          widget_info: c.widget_info,
          hide_widget: clsx(c.actions_item, c.hide_widget),
          copy_link: clsx(c.actions_item, c.copy_link),
        }}
      />
    </div>
  )
}
